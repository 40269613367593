@import url("https://fonts.googleapis.com/css2?family=Poppins");

.cardContainer {
  padding: 0 12px 22px 16px;
  object-fit: contain;
  margin: 15px 10px 15px 10px;
  border-radius: 22px;
  box-shadow: 0 12px 35px 0 rgba(0, 0, 0, 0.06);
  width: 200px;
  height: 316px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
}

.cardImg {
  width: 190px;
  max-height: 150px;
}

.productDescription {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.priceDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
  width: 85%;

  position: absolute;
  bottom: 10px;
}

.price {
  font-size: 20px;
  font-weight: 500;
}

.requestButton {
  font-size: 12px;
  font-weight: 500;

  cursor: pointer;
  padding: 10px 20px 10px 20px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #e0f3f1;
}

.priceButton {
  font-size: 12px;
  font-weight: 500;

  cursor: pointer;
  padding: 10px 20px 10px 20px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #e0f3f1;
}

.priceButton:hover {
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
  background-color: #2eafa3;
  color: #fff;
}

.discount {
  font-size: 12px;
  font-weight: 500;
  color: #14897e;
}

.hMarker {
  position: absolute;
  top: 5%;
  right: 5%;
  background: #2eafa3;
  border-radius: 50%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  padding: 2px 5px;
  box-shadow: 0px 12px 42px rgb(0 0 0 / 9%);
}
@media only screen and (max-width: 500px) {
  .cardContainer {
    width: 150px;
  }

  .price {
    font-size: 18px;
  }

  .discount {
    font-size: 12px;
  }

  .productDescription {
    font-size: 9px;
  }

  .priceButton {
    font-size: 9px;
    padding: 10px 15px 10px 15px;
  }

  .cardImg {
    width: 110px;
  }
}

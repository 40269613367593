@import url("https://fonts.googleapis.com/css2?family=Poppins");

.container {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: #000;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2% 15% 5% 15%;
  margin: 0 0 0 0;
}

.promo {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin: 0 20px 0 0;
}

.dt {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.0833333px;
  color: #4663d0;
  margin: 0 40px 20px 0;
}

.headDiv {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.title {
  text-align: left;
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
}

.subtitle {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  margin: 10px 0 20px 0;
}

.cartBody {
  width: 100%;
  display: flex;
}

.leftDiv {
  width: 60%;
  padding: 20px 0 20px 0;
}

.list {
  margin: -20px 0 0 0;
}

.rightDiv {
  width: 40%;
  padding: 20px 20px 20px 20px;
}

.summaryCard {
  padding: 35px;
  border-radius: 18px;
  box-shadow: 0 6px 53px 0 rgba(0, 0, 0, 0.06);
}

.horDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  border-bottom: solid 1px #24b2a640;
  font-family: "Poppins";
  font-size: 14px;
}

.shpDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
}

.tbpDiv {
  border-radius: 8px;
  background-color: #2eafa225;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
}

.tbp {
  font-weight: 600;
  color: #2eafa3;
}

.total {
  font-weight: 600;
}

.butDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 10px 10px 0;
}

.button {
  width: 145px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
  background-color: #2eafa3;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;

  cursor: pointer;
}

.lBottom {
  background: #ffeceb;
  border-radius: 18px;
  padding: 25px 25px 25px 25px;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lBottomTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}

.lBottomSubtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #4663d0;
}

.lBottomPhone {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
  border-radius: 25.5px;
  padding: 10px 20px 10px 20px;
  margin: 10px 0 0 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;

  color: #000000;
}

.phoneDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.promoDiv {
  margin: 20px 0 0 0;
}

.promo {
  border: none;
  outline: none;
  padding: 15px 25px 15px 25px;
  border-radius: 8px;
  box-shadow: 0 6px 53px 0 rgba(0, 0, 0, 0.06);
}
.shpDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
}
.tbpDiv {
  border-radius: 8px;
  box-shadow: 1px 10px 50px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
  margin: 10px 0 0 0;
}

.tbp {
  font-weight: 600;
  color: #2eafa3;
}

@media only screen and (max-width: 500px) {
  .body {
    padding: 20% 20px 10% 20px;
    flex-direction: column;
  }

  .cartBody {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    margin: 0 0 0 5%;
    font-size: 22px;
  }

  .subtitle {
    margin: 5px 0 20px 5%;
    text-align: left;
    font-style: italic;
    font-size: 12px;
  }

  .headDiv {
    margin: 0 0 0 20px;
  }

  .leftDiv {
    width: 90%;
  }

  .rightDiv {
    width: 90%;
  }

  .button {
    font-size: 14px;
    width: 100px;
    height: 35px;
  }

  .horDiv,
  .shpDiv,
  .tbpDiv {
  }
}

.promoDescription {
  font-weight: 300;
  color: #2eafa3;
  font-size: 13px;
}

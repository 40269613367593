@import url("https://fonts.googleapis.com/css2?family=Poppins");

.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 20% 5% 20%; 
}

.back {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.backicon {
    width: 100%;
}

.headDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.head {
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 20px 0;
}

.subtitle {
    text-align: left;
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.descDiv {
    margin: 30px 0 0 0;
}

.section1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.imgDiv {
    margin: 0 40px 0 0;
}

.cardImg {

}

.name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: left;
    margin: 40px 0 0 0;
}

.section {
    margin: 25px 0 25px 0;
}

.sectionTitle {
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.sectionBody {
    margin: 5px 0 0 0;
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.buttonsDiv {
    display: flex;
}

.addToCart {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;

    margin: 0 10px 0 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}

.buyNow {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.3);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;

    margin: 0 10px 0 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}

.priceDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;
    width: 100%;
}

.discount {
    font-size: 12px;
    font-weight: 500;
    color: #14897e;
}

.requestButton {
    font-size: 12px;
    font-weight: 500;

    cursor: pointer;
    padding: 10px 20px 10px 20px;
    object-fit: contain;
    border-radius: 10px;
    background-color: #e0f3f1;
}

.price {
    font-size: 20px;
    font-weight: 800;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 10px 0 0 0;
}

.ppiece {
    font-weight: normal;
    font-size: 16px;
    margin: 5px 0 0 5px;
}

.discount {
    font-size: 12px;
    font-weight: 500;
    color: #14897e;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 2px 0 0 0;
}

@media only screen and (max-width: 500px) {
    .body {
      padding: 10% 8% 10% 8%;
    }

    .imgDiv {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .section1 {
        flex-direction: column;
    }

    .back {
        left: 8%;
        top: 18%;
    }

    .cardImg {
        max-width: 100%;
    }

    .headDiv {
        margin: 100px 0 0 0;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins");

.fixedfooter {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 1000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .textblock23 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.11);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .link {
    color: #000;
    text-decoration: none;
  }
  
  .divblock22 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.11);
  }
  
  .image11 {
    width: 20px;
  }
  
  .image12 {
    width: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .image13 {
    width: 20px;
  }
  
  .linkblock {
    cursor: pointer;
    display: inline-block;
  }
  
  .linkblock2 {
    cursor: pointer;
    display: inline-block;
  }
  
  .linkblock3 {
    cursor: pointer;
    display: inline-block;
  }
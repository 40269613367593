@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.headerContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 15px 50px 15px 50px;
}

.menuContainer {
  display: flex;
  flex-direction: row;
}

.menuItem {
  margin: 10px 0px 10px 0px;
  padding: 5px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.logout {
  margin: 0 0 0 20px;
  position: absolute;
}

.profile {
  margin: 0 5px 0 -20px;
  position: absolute;
  cursor: pointer;
}

.signin {
  width: 80px;
  margin: 10px 0px 10px 0px;
  padding: 5px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.pharmacyMenuItem {
  margin: 10px 5px 10px 5px;
  padding: 5px 20px 5px 20px;
  background-color: #2eafa3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.53);
  text-decoration: none;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.cartIconItem {
  margin: 10px 10px 10px -10px;
  padding: 5px 15px 5px 25px;
  background-color: #e0f3f1;
  border-radius: 10px;
  z-index: -1;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.53);
  position: relative;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #24b2a5;
  cursor: pointer;
}

.cartCount {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f75906;
  color: white;
  font-size: 10px;
  box-shadow: 4px 4px 15px rgba(255, 0, 0, 0.25);
}

.cover {
  padding: 5px 15px 5px 25px;
  position: relative;
  left: 20px;
  margin: 0 -25px 0 -25px;
  cursor: pointer;
}

.covidCareDiv {
  display: flex;
}

.comingSoon {
  position: absolute;
  right: 200px;
  top: 65px;

  font-family: "Poppins";
  font-size: 11px;
  font-weight: 500;
  color: #fa6400;
}

.covidCareMenuItem {
  margin: 10px 10px 10px 10px;
  padding: 5px 20px 5px 20px;
  background-color: rgba(46, 175, 163, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.alarm {
  color: #24b2a5;
  margin-left: -25px;
}

.logo {
  height: 30px;
  width: 120px;
}

.brandBgNav {
  position: absolute;
  top: 0;
  left: 15px;
  z-index: -1;
}

.loginModalBg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.54);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginModal {
  padding: 50px 100px 50px 100px;
  background-color: #fff;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginLogo {
  width: 75px;
  margin: 0 0 10px 0;
}

.modalTitle {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px;
  margin: 0 0 20px 0;
  text-align: center;
  width: 70%;
}

.phoneNumberDiv {
  background: #e0f3f1;
  border-radius: 10px;
  width: 350px;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}

.phoneNum {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2eafa3;
  opacity: 0.6;
  border: none;
  outline: none;
  background-color: transparent;
}

.sendOTP {
  padding: 5px 10px 5px 10px;
  background: #fefeff;
  /* Green shadow */
  box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
  border-radius: 5px;
  cursor: pointer;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  color: #2eafa3;
}

.or {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;

  margin: 0 0 20px 0;
}

.socialLogo {
  width: 10%;
}

.signupText {
  width: 90%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.socialButtonE {
  width: 350px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(120.24deg, #e3e3ff 10.68%, #afa4c6 100%);
  margin: 10px 0 10px 0;
  cursor: pointer;
}

.socialButtonG {
  width: 350px;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(180deg, #ffd5d3 0%, #e7b7b5 100%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0;
  cursor: pointer;
}

.socialButtonF {
  width: 350px;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(152.43deg, #6ca0da 24.65%, #5874de 98.71%);
  border: 1px solid #6c9fda;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0;
  cursor: pointer;
}

.ltablet {
  position: absolute;
  top: 100px;
  left: 32%;
}

.ltabletO {
  position: absolute;
  top: 20%;
  left: 32%;
}

.rtablet {
  position: absolute;
  top: 60%;
  right: 28%;
}

.rtabletO {
  position: absolute;
  top: 60%;
  right: 26%;
}

.phoneNumberDivOTP {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 40px 0;
}

.phoneNumOTP {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  color: #4663d0;
}

.editnum {
  margin: 0 0 0 10px;
  cursor: pointer;
}

.otpDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otpsubmit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 20px;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  cursor: pointer;
  background: linear-gradient(152.43deg, #6ca0da 24.65%, #5874de 98.71%);
}

.resendOTPDiv {
  margin: 15px 0 0 0;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  text-decoration-line: underline;

  cursor: pointer;

  color: #4663d0;
}

.ourStory {
  text-decoration: none;
  color: black;
}
.brand {
  cursor: pointer;
}
.cartContainer {
  margin: 10px 5px 10px 5px;
  padding: 5px 20px 5px 20px;

  background-color: #2eafa3;
  border-radius: 10px;

  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.53);
  text-decoration: none;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  position: relative;
}
.marginRight {
  margin-right: 10px;
}
@media only screen and (max-width: 500px) {
  .menuContainer {
    /* display: none; */
    justify-content: flex-end;
    /* margin-right: -45px; */
  }

  .headerContainer {
    /* border: 2px solid green; */
  }

  .menuItem,
  .pharmacyMenuItem,
  .covidCareDiv {
    display: none;
  }

  .logout {
    margin: 0 0 0 10px;
  }

  .cartIconItem {
    margin-right: -5px;
    padding: 5px 15px 5px 15px;
  }

  .logo {
    height: 30px;
    width: 100px;
  }

  .cover {
  }

  .signin {
    justify-content: flex-end;
    padding: 5px 5px 5px 0px;
  }

  .ltablet {
  }

  .ltablet {
    position: absolute;
    top: 100px;
    left: 0%;
  }

  .rtablet {
    position: absolute;
    top: 85%;
    right: 28%;
  }

  .ltabletO {
    left: 10%;
  }

  .rtabletO {
    top: 72%;
  }

  .loginModal {
    padding: 50px 10px 50px 10px;
    margin: 0 20px 0 20px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Roboto");


.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.back {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.backicon {
    width: 100%;
}

.innerBody {
    align-self: flex-end;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 92%;
}

.left {
    width: 30%;
}

.lTop {
    /* height: 45vh; */
    margin: 0 20px 20px 0;
    padding: 40px 40px 0px 40px;
    background: #F3F5FF;
    border-radius: 18px;
}

.lTopTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
}

.lTopSubtitle {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    margin: 20px 0 0 0;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 0 20px 0;
    padding: 20px 70px 25px 70px;
    background: #FFFFFF;
    box-shadow: 0px 10px 41px rgba(0, 0, 0, 0.0901169);
    border-radius: 18px;
}

.text {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.78px;
    color: #000000;
    margin: 0 0 -40px 0;
}

.profile {
    margin: 100px 0 0 0;
}

.done {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 25px 10px 25px;
    margin: 40px 0 0 0;
    cursor: pointer;
    text-decoration: none;
}

.submitted {
    margin: -40px 0 0 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.78px;
    color: #2EAFA3;
}

.profile2 {
    width: 120%;
    position: relative;
    bottom: 0;
    right: -80px;
}

.tablet {
    position: absolute;
    right: 7.5%;
    top: 35%;
}

@media only screen and (max-width: 500px) {
    .body {
        padding: 10% 8% 10% 8%;
    }

    .back {
        left: 12%;
        top: 18%;
    }

    .innerBody {
        flex-direction: column;
    }

    .lTopSubtitle {
        margin-bottom: 20px;
    }

    .left {
        margin: 100px 0 0 0;
        width: 100%;
    }

    .right {
        width: 90%;
        padding: 40px 10px 45px 10px;
    }

    .text {
        margin: 0 0 -15px 0;
        z-index: 1;
        font-size: 12px;
    }

    .submitted {
        margin: -10px 0 0 0;
        font-size: 22px;
        z-index: 1;
    }

    .statusill {
        width: 60%;
    }

    .profile {
        margin-top: 40px;
    }

    .done {
        font-size: 14px;
        padding: 10px 20px 10px 20px;
    }

    .profile2 {
        width: 100%;
        right: 0;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Roboto");

.cartItemContainer {
  padding: 17px 29px 16px;
  object-fit: contain;
  border-radius: 18px;
  background-color: #f3f5ff;
  margin: 20px 0 20px 0;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.originalPrice {
  margin-right: 10px;
  font-size: 12px;
  text-decoration: line-through;
  opacity: 0.6;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.name {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.nameRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.quantityModifierW {
  opacity: 0.6;
}

.isPrescriptionDrug {
  margin: 0 0 0 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 70%;
  letter-spacing: -0.3px;
  color: #ffffff;

  background: linear-gradient(152.43deg, #748bdf 24.65%, #5874de 98.71%);
  box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.509151);
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
}

.description {
  font-family: "Poppins";
  font-size: 10px;
  margin: 5px 0 5px 0;
}

.del {
  margin: 10px 0 0 0;
}

.right {
  display: flex;
}

.price {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  color: #24b2a5;
}

.inputgroup {
  background-color: transparent;
  background-color: #fff;
  padding: 4px 0 4px 0;
  border-radius: 10px;
}

.minus {
  border: none;
  background-color: transparent;
  padding: 2px 10px 2px 10px;
  text-align: center;
  cursor: pointer;
}

.val {
  width: 20px;
  text-align: center;
  border: none;
  padding: 2px 8px 2px 9px;
  border-radius: 7px;
  background-color: #f3f5ff;
  margin: 0 5px 0 5px;

  font-family: "Poppins";
  font-size: 14px;
  color: #24b2a5;
}

.plus {
  border: none;
  background-color: transparent;
  padding: 2px 10px 2px 10px;
  text-align: center;
  cursor: pointer;
}

.top {
  display: flex;
  justify-content: space-between;
}

.bottom {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomW {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 500px) {
}

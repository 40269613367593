body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Toastify__toast {
  box-shadow: 1px 1px 0 rgb(0 0 0 / 10%), 0 2px 5px 0 rgb(0 0 0 / 5%) !important;
}

.pointer {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}

.hFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.hcFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

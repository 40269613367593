@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Roboto");


.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.back {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.backicon {
    width: 100%;
}

.innerBody {
    align-self: flex-end;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    width: 92%;
}

.left {
    width: 30%;
}

.lTop {
    /* height: 45vh; */
    margin: 0 20px 20px 0;
    padding: 40px 40px 0px 40px;
    background: #F3F5FF;
    border-radius: 18px;
}

.lTopTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
}

.lTopSubtitle {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    margin: 20px 0 0 0;
}

.right {
    width: 70%;
    padding: 20px 70px 25px 70px;
    background: #FFFFFF;
    box-shadow: 0px 10px 41px rgba(0, 0, 0, 0.0901169);
    border-radius: 18px;
}

.profile {
    margin: 100px 0 0 0;
}

.prescription {
    width: 120%;
    position: relative;
    bottom: 0;
    right: -100px;
}

.edit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    cursor: pointer;
}

.editText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px; 
    text-decoration-line: underline;
    color: #4663D0;
    margin: 0 10px 0 0;
    mix-blend-mode: normal;
}  

.details {
    margin: 0 0 0 0;
}

.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 0 15px 0;
}

.rowLeft {
    width: 150px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
}

.rowRight {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: left;  
    color: #000000;   
    mix-blend-mode: normal;
    opacity: 0.5;
}

.pincode {
    background: #5D78DE;
    border-radius: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 5.48333px;
    color: #FFFFFF;  
    text-align: center;
    padding: 10px 10px 10px 12px;  
    margin: 0 25px 0 0;
}

.save {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 8px 20px 8px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.saveDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 30px 0 0 0;
}

.blankDiv {
    height: 75px;
}

.emailI, .phoneI, .nameI {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 15px 10px 15px;
    margin: 0 0px 0 0px;
    width: 30%;
}

.pincodeI {
    background: #5D78DE;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 0 0 0 0;
    width: 85px;
    text-align: left;

    letter-spacing: 5.48333px;
    color: #FFFFFF;
}

.pincodeI::placeholder {
    color: white;
  }

.addressI {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 10px 0 0 0;
    width: 350px;
}

.addDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailsDiv {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0 0 0;
}

.yourDetailsText {
    width: 120px;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 14px;
}

.name, .phone {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 0 10px 0 10px;
    width: 50%;
}

.email {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 0 10px 0 10px;
    width: 170%;
}

.pincode {
    background: #5D78DE;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 6px 20px 6px 20px;
    margin: 0 10px 0 10px;

    letter-spacing: 5.48333px;
    color: #FFFFFF;
}

.pincode::placeholder {
    color: white;
  }

.pinDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
}

.tablet {
    position: absolute;
    right: 7.5%;
    top: 35%;
}

.address {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 20px 0 0 0;
    width: 100%;
}

.add {
    display: flex;
    align-items: center;
    width: 50%;
}

.addI {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}

.addDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.bookDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0 0;
}

.book {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;

    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .body {
      padding: 10% 8% 10% 8%;
    }

    .innerBody {
        flex-direction: column;
    }

    .left {
        margin: 100px 0 0 0;
        width: 100%;
    }

    .lTop {
        margin-right: 10px;
    }

    .profile {
        margin: 20px 0 0 0;
    }

    .back {
        left: 15%;
        top: 18%;
    }

    .prescription {
        margin-top: 0;
        right: 5%;
    }

    .right {
        width: 80%;
        padding: 20px 25px 25px 25px;
    }

    .yourDetailsText {
        width: 70px;
        font-size: 12px;
    }

    .name, .email, .phone {
        font-size: 12px;
        margin: 0 5px 0 5px;
    }

    .add {
        width: 100%;
    }

    .addDiv {
        font-size: 12px;
        width: 80%;
    }

    .pinDiv {
        width: 50%;
    }

    .pincode {
        font-size: 12px;
        max-width: 100%;
    }

    .addI {
        width: 100%;
    }

    .book {
        font-size: 14px;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins");

.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 5% 15% 5% 15%;
    height: 67vh;
}

.left {
    width: 100%;
}

.right {
    padding: 0 50px 0 80px;
}

.innerDiv {
    border-radius: 18px;
    background-color: #f3f5ff;
    padding: 50px 20px 50px 20px;

}

.innerDivTick {
    border-radius: 18px;
    background-color: rgba(36, 178, 165, 0.1);
    padding: 50px;
}

.crossDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: -20px 20px 0 10px;
}

.uploadDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 30px 0 30px;
}

.uploadTitleDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
}

.uploadImageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.browseButtonDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
}

.uploadTitle {
    width: 40%;
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    text-align: left;
    margin: 0 0 0 20px;
}

.browseButtonText {
    text-align: left;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 300;
    margin: 10px 0 0 0;
}

.browseDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.browseButton {
    margin: 10px 0 0 0;
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    color: white;
    width: 80px;
}

.proceed {
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    color: white;
    width: 45%;
    text-decoration: none;
}

.proceedDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.orDiv {
    margin: 15px 10px 15px 10px;
}

.takePicButton {
    margin: 10px 0 0 0;
    padding: 10px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    color: white;
    width: 160px;
}

.camDiv {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    width: 100vw; height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f5ff;
}

input[type="file"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    width: 125px;
    margin: 0 0 0 -30px;
}

.filenamediv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    margin: 0 0 30px 0;
    width: 60%;
}

.filename {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 0 10px;
}

.bcross {
    cursor: pointer;
}

.dragndrop {
    width: 150%;
}

.rightTitleDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rightTitle {
    font-family: "Poppins";
    text-align: left;
    font-weight: 600;
    font-size: 30px;
}

.btm {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottomCall {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
    border-radius: 25.5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    padding: 10px 20px 10px 20px;
}

.number {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;  
    color: #4663D0;
    text-decoration: none;
    cursor: pointer;
}


@media only screen and (max-width: 500px) {
    .body {
        padding: 15% 8% 10% 8%;
    }

    .uploadDiv {
        margin: 20px 0 0 0;
        flex-direction: column;
        align-items: flex-start;
    }

    .uploadTitle {
        font-size: 22px;
    }

    .dragndrop {
        width: 100%;
    }

    .uploadTitleDiv {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 0 20px;
    }

    .uploadImageDiv {
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 30px 0 0 0;
    }
    
    .browseDiv {
        width: 100%;
        margin: 0 0 40px 0;
    }

    .proceedDiv {
        margin: 0 0 40px 0;
        align-items: center;
        justify-content: center;
    }

    .browseButtonDiv {
        width: 100%;
    }
}
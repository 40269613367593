@import url("https://fonts.googleapis.com/css2?family=Poppins");

.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.orderStatus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 80%;
}

.section {
    margin: 100px 0 0 0;
}

.titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleSectionText {
    font-size: 30px;
    font-weight: 600;
    text-align: left;
}

.titleSectionButton {
    padding: 12px 40px 14px 41px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;

    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.plus2Div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 20px;
}

.bodySection {
    margin: 20px 0 20px 0;
}

.covidEssentialsDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}

.covidEssentialsDiv2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    padding: 20px 20px 20px 20px;
}

.covidEssential {
    width: 25%;
    padding: 32px 128px 137px 40px;
    margin: 0 30px 0 0;
    object-fit: contain;
    border-radius: 20px;
    background-image: linear-gradient(128deg, #eff5ff 23%, #ffffff 95%);

    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    text-align: left;
}

.covidarrow {
    margin: 50px 0 0 0;
    position: absolute;
}

.mask, .sanitizer, .handwash {
    margin: 30px 0 0 100px;
    position: absolute;
}

.bookApptDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;
    padding: 15px;
    background: #EFF5FF;
    border-radius: 24px;
}

.apptLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60%;
}

.plus {
    margin: 10px 0 10px 0px;
}

.apptTitle {
    margin: 10px 0 10px 70px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.apptRight {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.3);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 20px 0 0;
    text-decoration: none;
    cursor: pointer;
}

.searchDiv {
    margin: 0 0 0 0;
    width: 96.5%;
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
}

.search {
    width: 80%;
    border: none;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}

.searchIconDiv {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.3);
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchicon {
}

.lastOrderDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
    padding: 15px;
    background: #E0F3F1;
    border-radius: 24px;
}

.lastOrderDiv2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
    padding: 15px;
    background: #E0F3F1;
    border-radius: 24px;
}

.repeatOrder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.robutton {
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #2EAFA3;
    margin: 0 20px 0 0;
    text-decoration: none;
    cursor: pointer;
}

.orderHistory {
    border-left: 2px dotted #000000;
    padding: 10px 20px 10px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-decoration-line: underline;
    color: #4663D0;
    cursor: pointer;
}

.orderHistoryTitle {
    margin: 10px 0 10px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
}

.orderHistoryTitleP {
    margin: 10px 0 10px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
}

.nof {
    margin: 0 20px 0 0;
    font-size: 14px;
}

.orderDetails {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

.orderId {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    text-align: left;
}

.topOH {
    padding: 0 0 0 30px;
}

.date {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    text-align: left;
    margin: 0 0 0 10px;
}

.medicines {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

@media only screen and (max-width: 768px) {
    .body {
      padding: 10% 20px 10% 20px;
      width: 85%;
    }

    .outerBody {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
    }

    .titleSectionText {
        font-size: 20px;
    }

    .lastOrderDiv {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 50px;
    }

    .covidEssential {
        font-size: 14px;
        padding: 21px 120px 50px 20px;
    }

    .brSpan {
        /* display: none; */
    }

    .titleSectionButton {
        font-size: 16px;
        padding: 15px 20px 15px 20px;
    }

    .covidEssentialsDiv {
        flex-direction: column;
    }

    .covidEssential {
        margin: 20px 0 20px 0;
        padding: 20px 108px 80px 40px;
        width: 50%;
    }

    .covidarrow {
        width: 25px;
        margin: 20px 0 0 0;
    }

    .mask, .sanitizer, .handwash {
        width: 120px;
        margin: 0px 0 0 150px;
        position: absolute;
    }
    
    .searchDiv {
        width: 89%;
    }

    .bookApptDiv {
        padding: 50px;
        flex-direction: column;
        align-items: flex-start;
    }

    .plus {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .apptTitle {
        text-align: left;
        margin-left: 0px;
        font-size: 16px;
        width: 80%;
    }

    .apptRightDiv {
        margin: 10px 0 10px -20px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .apptRight {
        padding: 8px 10px 8px 10px;
        width: 100px;
        line-height: 1.5;
        font-size: 14px;
    }

    .plus2Div {
        width: 100%;

        margin-left: -45px;
    }

    .apptLeft {
        /* margin-left: -45px; */
        max-width: 100%;
        width: 100%;
        margin-top: -35px;
        margin-left: -20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .orderStatus {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }

    .plus2 {
        margin-left: -170px;
    }

    .orderHistoryTitle {
        font-size: 16px;
    }

    .topOH {
        padding: 0;
        margin: -10px 0 15px 0px;
    }

    .orderId {
        margin: 0 0 0 -45px;
    }

    .medicines {
        font-size: 10px;
        margin: -5px 0 0 -45px;
    }

    .robutton {
        margin: 10px 25px 10px -25px;
        padding: 8px 10px 8px 10px;
        width: 100px;
        font-size: 12px;
        line-height: 1.2;
    }

    .repeatOrder {
        width: 100%;
        margin-left: 0px;
        padding: 0;
    }

    .search {
        font-size: 14px;
    }

    .searchIconDiv {
        width: 2%;
        height: 25px;
    }

    .searchicon {
        width: 20px;
    }

    .orderHistory {
        font-size: 12px;
    }

    .orderId {
        margin-left: 0;
    }

    .medicines {
        margin-left: 0;
    }

    .orderHistoryTitle {
        margin-left: 0;
    }

    .orderHistoryTitleP {
        margin-left: -100px;
    }

    .topOH {
        padding: 0 0 0 0;
    }

    .nof {
        margin-left: -20px;
    }

  }



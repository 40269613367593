@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Roboto");

.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 10% 5% 10% ;
    height: 65vh;
}

.bluebox {
    height: 120%;
    width: 80%;
    background: #F3F5FF;
    border-radius: 18px;
    padding: 20px 50px 20px 50px;
    position: relative;
}

.crossDiv {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
}

.outerDiv {
    display: flex;
    height: 100%;
}

.leftDiv {
    width: 35%;
}

.leftText {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.leftDivTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.65px; 
    color: #000000; 
}

.leftDivSub {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.65px; 
    color: #000000; 
}

.leftDivFreeConsultation {
    margin: 10px 0 0 0;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px; 
    color: #5D78DE;
    text-align: left;
}

.rightDiv {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.row {
    display: flex;
    margin: 8px 0 8px 0;
}

.tag {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
    width: 200px;
}

.timeslotsDiv {
    display: flex;
}

.timeslotActive {
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.0973285);
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    cursor: pointer;
}

.timeslot {
    text-align: left;
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.inputText {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 200px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.inputSelect {
    background: #5D78DE;
    box-shadow: 0px 2px 21px rgba(93, 120, 222, 0.336375);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 220px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}


.option {
    color: #ffffff;
}


.videocall {
    position: relative;
    width: 65%;
}

.imgDiv {
    position: absolute; 
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.book {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 8px 50px 8px 50px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;

}

@media only screen and (max-width: 500px) {
    .body {
        padding: 10% 8% 10% 8%;
        height: auto;
    }

    .bluebox {
        height: 100%;
        padding: 20px 10px 20px 20px;
    }

    .outerDiv {
        flex-direction: column;
    }

    .leftDiv {
        width: 100%;
    }

    .imgDiv {
        display: none;
    }

    .rightDiv {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .leftDivTitle {
        font-size: 18px;
        line-height: 1.2;
    }

    .leftDivSub {
        font-size: 18px;
        line-height: 1.2;
    }

    .leftDivFreeConsultation {
        font-size: 8px;
    }

    .row {
        margin: 5px 0 5px 0 ;
    }

    .tag {
        font-size: 10px;
        width: 100px;
    }

    .inputText {
        font-size: 10px;
        width: 150px;
    }

    .inputSelect {
        font-size: 10px;
        width: 170px;
    }

    .timeslot {
        font-size: 10px;
        padding: 10px;
        margin: 0 2.5px 0 2.5px;
    }

    .timeslotActive {
        font-size: 10px;
        padding: 10px;
        margin: 0 2.5px 0 2.5px;
    }

    .book {
        font-size: 14px;
        padding: 4px 30px 4px 30px;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Roboto");


.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.back {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.backicon {
    width: 100%;
}

.innerBody {
    align-self: flex-end;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    width: 92%;
}

.left {
    width: 30%;
}

.lTop {
    /* height: 45vh; */
    margin: 0 20px 20px 0;
    padding: 40px 40px 0px 40px;
    background: #F3F5FF;
    border-radius: 18px;
}

.lTopTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
}

.lTopSubtitle {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    margin: 20px 0 0 0;
}

.lBottom {
    background: #FFECEB;
    border-radius: 18px;
    padding: 25px 25px 25px 25px;
    margin: 0 20px 20px 0;
}

.lBottomTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
}

.lBottomSubtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #4663D0;
}

.lBottomPhone {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
    border-radius: 25.5px;
    padding: 10px 20px 10px 20px;
    margin: 10px 0 0 0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;

    color: #000000;

}

.phoneDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.right {
    width: 70%;
    padding: 70px 70px 70px 70px;
    background: #FFFFFF;
    box-shadow: 0px 10px 41px rgba(0, 0, 0, 0.0901169);
    border-radius: 18px;
}

.prescription {
    margin: 30px 0 0 0;
}

.prescription2 {
    width: 100%;
    position: relative;
    bottom: 0px;
    right: -100px;
}

/** ORDER HISTORY CARD STYLES */

.cardContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 30px 10px 30px;
    padding: 15px 30px 15px 30px;
    background: #FBFBFB;
    border-radius: 10px;    
}

.cardLeft {
}

.orderId {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: left;
    letter-spacing: -0.116667px;
    color: #000000;
    opacity: 0.4;
}

.date {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.116667px;
    color: #000000;
    text-align: left;
    margin: 5px 0 0 0;
}

.productsList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
}

.product {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.0833333px;
    color: #000000;
    text-align: left;
    margin: 5px 5px 0 0;
}

.cardRight {
    display: flex;
    align-items: center;
}

.price {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    text-align-last: left;
    color: #24B2A5;
    padding: 10px 25px 10px 25px;
}

.reorder {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.2);
    border-radius: 6px;
    padding: 10px 25px 10px 25px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    
    text-decoration: none;
    cursor: pointer;
}

.tablet {
    position: absolute;
    right: 7.5%;
    top: 35%;
}

@media only screen and (max-width: 500px) {
    .body {
      padding: 10% 8% 10% 8%;
    }
    
    .innerBody {
        flex-direction: column;
    }

    .prescription2 {
        right: 0;
    }

    .left {
        margin: 100px 0 0 0;
        width: 100%;
    }

    .right {
        padding: 0;
        width: 93%;
    }

    .back {
        top: 18%;
    }

    .cardContainer {
        flex-direction: column;
        margin: 15px 15px 15px 15px;
        padding: 15px 15px 15px 15px;
    }

    .cardLeft {
        margin-left: 0;
        width: 100%;
    }

    .cardRight {
        margin: 30px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .price {
        padding: 10px 0px 10px 0px;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins");

.mainCard {
    display: flex;
    padding: 60px 60px 60px 60px;
    border-radius: 12px;
    box-shadow: 0 12px 42px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
}

.mainCardLeft {
    width: 40%;
    text-align: left;
}

.mainCardLeftTitle {
    font-size: 250%;
    line-height: 1;
    font-weight: 600;
}

.mainCardLeftSubtext {
    margin-top: 15px;
    font-size: 75%;
    font-weight: 300;
}

.mainCardRight {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mainCardRightCoupon {
    width: 40%;
    text-align: left;
    border-radius: 18px;
    border: dashed 2px #5b77de;
    background-color: #eff5ff;
    padding: 50px;
    font-size: 125%;
    font-weight: normal;
}

.mainCardRightCouponSpan {
    font-weight: 600;
}

.mainCardImgDiv{
    position: relative;
}

.mainCardImg {
    position: absolute;
    bottom: -35%;
    left: -35px;
    max-width: 450px;
}

/* Safari Specific Code */
_::-webkit-full-page-media, _:future, :root .mainCardImg {
    position: absolute;
    bottom: 40.5%;
    left: 40%;
    width: 30%;
} 

.bottomCardDiv {
    margin: 30px 0 30px 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.bold {
    font-weight: bolder;
}

.bottomCardLeft {
    margin: 0 15px 0 0;
    padding: 45px 45px 45px 45px;
    width: 50%;
    background: linear-gradient(180deg, #EFF5FF 0%, #E2E9FB 100%);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    color: #000;
}

.bottomCardCenter {
    margin: 0 0 0 0;
    padding: 45px 45px 45px 45px;
    width: 50%;
    background: linear-gradient(180deg, #EFF5FF 0%, #E2E9FB 100%);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    color: #000;
}

.bottomCardTitleDiv {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.bottomCardTitle {
    font-size: 25px;
    font-weight: 400;
    color: #000;
    text-align: left;
}

.bottomCardSubtext {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    color: #000;
    text-align: left;
    width: 90%;
}

.injection {
    position: absolute;
    top: auto;
    margin: -65px 0 0 -85px;
}

.bottomCardRight {
    position: relative;
    margin: 0 0 0 15px;
    padding: 45px 45px 45px 45px;
    width: 50%;
    background: linear-gradient(180deg, #EFF5FF 0%, #E2E9FB 100%);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    cursor: pointer;
    color: #000;
}

.capsules {
    position: absolute;
    bottom: -11%;
    right: -10%;
}

@media only screen and (max-width: 1024px){
    .mainCardLeftTitle{
        font-size: 200%;
    }
    .mainCardRightCoupon{
        padding: 30px;
        font-size: 100%;
    }
    .mainCardImg{
        max-width: 320px;
        bottom: -50%;
        left: -45px;
    }
}

@media only screen and (max-width: 768px){
    .mainCardLeftTitle{
        font-size: 150%;
    }
    .mainCardRightCoupon{
        padding: 25px;
        font-size: 83%;
    }
    .mainCardImg{
        max-width: 250px;
        bottom: -50%;
        left: -45px;
    }
    .injection{
        margin: -65px 0 0 -48px;
    }
    .capsules{
        bottom: -6%;
        right: -15%;
    }
}




@media only screen and (max-width: 500px) {
    .mainCard {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 50px 20px;
    }

    .mainCardLeft {
        width: 100%;
    }

    .mainCardLeftTitle {
        font-size: 22px;
    }
    
    .mainCardLeftSubtext {
        margin-top: 10px;
        font-size: 10px;
    }
    
    .mainCardRight {
        margin-top: 40px;
        width: 100%;
    }
    
    .mainCardRightCoupon {
        width: 100%;
        padding: 20px 20px 70px 20px;
    
        font-size: 16px;
    }
    
    .mainCardRightCouponSpan {
        font-weight: 600;
    }

    .mainCardImgDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .mainCardImg {
        bottom: -241px;
        left: auto;
        right: auto;
        width: 70%;
    }


    .bottomCardDiv {
        margin: 60px 0 60px 0;
        display: flex;
        flex-direction: row;
    }

    .bottomCardCenter {
        padding: 30px 12px 30px 12px;
        text-decoration: none;
        cursor: pointer;
    }
    
    .bottomCardLeft {
        padding: 30px 12px 30px 12px;
        margin: 0 8px 0 0;
        text-decoration: none;
        cursor: pointer;
    }
    
    .bottomCardTitleDiv {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .bottomCardTitle {
        font-size: 14px;
    }
    
    .bottomCardSubtext {
        display: none;
    }
    
    .injection {
        transform: rotate(-290deg);
        margin: 8% 0 0 20px;
        display: none;
    }
    
    .bottomCardRight {
        padding: 30px 12px 30px 12px;
        cursor: pointer;
        margin: 0 0 0 8px;
    }

    .bottomCardRightArrow {
        width: 25px;
        margin: 50px 0 0 0;
        display: none;
    }

    .bottomCardUpArrow {
        width: 25px;
        margin: 50px 0 0 0;
    }
    
    .capsules {
        /* top: 94%; */
        bottom: 15%;
        left: 58%;
        width: 50%;
        display: none;
    }
  }
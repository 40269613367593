@import url("https://fonts.googleapis.com/css2?family=Poppins");


.footer {
    position: relative;
    z-index: 1500;
    padding: 5vh 50px;
    background-color: #4663d0;
}

.divblock23 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
  
.divblock24 {
    display: none;
    margin-bottom: 20px;
}

.divblock25 {
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
}
  
.divblock26 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
}

.textblock24 {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
}
  
.textblock25 {
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
}

.textblock26 {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
}

.link {
    text-decoration: none;
}
  
.textblock27 {
    margin-right: 10px;
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.textblock39 {
  font-size: 14px;
}



@media only screen and (max-width: 420px) {
    .footer {
        padding: 5vh 25px;
        display: flex;
        align-items: center;
        /* width: 100%; */
    }
}

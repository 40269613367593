@import url("https://fonts.googleapis.com/css2?family=Poppins");

.apptNotificationDiv {
    border-radius: 24px;
    background-color: #f2dcde;
    display: flex;
    padding: 26px;
    align-items: center;
}

.notifTitleDiv {
    width: 20%;
    border-right: 2px dashed black;
}

.notifTextDiv {
    width: 60%;
}


.notifTitle {
    width: 136px;
    margin: 4px 53px 3px 23px;
    font-family: 'Poppins';
    text-align: left;
    font-size: 125%;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000000;
}

.notifText {
    margin: 9px 0 0 16px;
    font-family: Poppins;
    font-size: 85%;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
}

@media only screen and (max-width: 1024px){
    .notifTitle{
        font-size: 110%;
    }
}

@media only screen and (max-width:768px){
    .notifTitleDiv{
        width: 27%;
    }
    .calendar{
        width: 25px;
    }
}

@media only screen and (max-width:425px){
    .calendarIconDiv{
        width: 20%;
    }
}

@media only screen and (max-width: 500px) {
    .apptNotificationDiv {
        flex-direction: column;
        padding: 15px;
        align-items: flex-start;
    }

    .calendarIconDiv {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin: 10px 0 10px 20px;
    }
    
    .notifTitleDiv {
        width: 100%;
        border-right: none;
        display: flex;
        align-items: flex-start;
        margin: 10px 0 10px 20px;
    }
    
    .notifTextDiv {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin: 10px 0 10px 20px;
    }
    
    
    .notifTitle {
        margin: 0px 20px 0px 0px;
        font-family: 'Poppins';
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        color: #000000;
    }
    
    .notifText {
        margin: 0px 20px 0px 0px;
        font-family: 'Poppins';
        text-align: left;
        font-size: 10px;
        color: #000000;
    }
}
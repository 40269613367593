@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Roboto);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Toastify__toast {
  box-shadow: 1px 1px 0 rgb(0 0 0 / 10%), 0 2px 5px 0 rgb(0 0 0 / 5%) !important;
}

.pointer {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}

.hFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.hcFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App_App__16ZpL {
  text-align: center;
}

.App_App-logo__25k4o {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App_App-logo__25k4o {
    animation: App_App-logo-spin__1e7sv infinite 20s linear;
  }
}

.App_App-header__xLkWl {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App_App-link__3FsH9 {
  color: #61dafb;
}

.App_successToaster__nlWBK {
  background: #d5eef3;
  border-radius: 10px;
  padding: 18px 32px;
  z-index: 10000;
  margin-bottom: 10px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.App_errorToaster__3xOpy {
  background: #ffd9c3;
  border-radius: 10px;
  padding: 18px 32px;
  margin-bottom: 10px;
  z-index: 10000;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.App_infoToaster__23ipB {
  background: #ced2f4;
  border-radius: 10px;
  padding: 18px 32px;
  margin-bottom: 10px;
  z-index: 10000;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.App_toasterText__25EZv {
  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: black;
  z-index: 10000;
}

@keyframes App_App-logo-spin__1e7sv {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App_errorImg__kUz1i {
  margin-right: 15px;
}

.Home_container__1nkZs {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.Home_body__2UfUW {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.Home_orderStatus__1B1oI {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 80%;
}

.Home_section__zGJUu {
    margin: 100px 0 0 0;
}

.Home_titleSection__1CjmP {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Home_titleSectionText__Z7CQR {
    font-size: 30px;
    font-weight: 600;
    text-align: left;
}

.Home_titleSectionButton__2ejH8 {
    padding: 12px 40px 14px 41px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;

    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.Home_plus2Div__1lcuM {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 20px;
}

.Home_bodySection__2z7Te {
    margin: 20px 0 20px 0;
}

.Home_covidEssentialsDiv__2Cb3T {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}

.Home_covidEssentialsDiv2__3KqSP {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    padding: 20px 20px 20px 20px;
}

.Home_covidEssential__1EL5N {
    width: 25%;
    padding: 32px 128px 137px 40px;
    margin: 0 30px 0 0;
    object-fit: contain;
    border-radius: 20px;
    background-image: linear-gradient(128deg, #eff5ff 23%, #ffffff 95%);

    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    text-align: left;
}

.Home_covidarrow__fH6-Y {
    margin: 50px 0 0 0;
    position: absolute;
}

.Home_mask__6uUWw, .Home_sanitizer__20vL_, .Home_handwash__1fci8 {
    margin: 30px 0 0 100px;
    position: absolute;
}

.Home_bookApptDiv__1J8Hh {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;
    padding: 15px;
    background: #EFF5FF;
    border-radius: 24px;
}

.Home_apptLeft__3Mxkh {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60%;
}

.Home_plus__2k78g {
    margin: 10px 0 10px 0px;
}

.Home_apptTitle__uFk-x {
    margin: 10px 0 10px 70px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.Home_apptRight__2so74 {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.3);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 20px 0 0;
    text-decoration: none;
    cursor: pointer;
}

.Home_searchDiv__2igrF {
    margin: 0 0 0 0;
    width: 96.5%;
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
}

.Home_search__7NRlZ {
    width: 80%;
    border: none;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}

.Home_searchIconDiv__2r0vV {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.3);
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home_searchicon__3QVTV {
}

.Home_lastOrderDiv__2LDYC {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
    padding: 15px;
    background: #E0F3F1;
    border-radius: 24px;
}

.Home_lastOrderDiv2__TTeUe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 0;
    padding: 15px;
    background: #E0F3F1;
    border-radius: 24px;
}

.Home_repeatOrder__3FOuQ {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home_robutton__2oPBL {
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #2EAFA3;
    margin: 0 20px 0 0;
    text-decoration: none;
    cursor: pointer;
}

.Home_orderHistory__3UasI {
    border-left: 2px dotted #000000;
    padding: 10px 20px 10px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #4663D0;
    cursor: pointer;
}

.Home_orderHistoryTitle__2KJUB {
    margin: 10px 0 10px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
}

.Home_orderHistoryTitleP__KLJkW {
    margin: 10px 0 10px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
}

.Home_nof__6GQ7Y {
    margin: 0 20px 0 0;
    font-size: 14px;
}

.Home_orderDetails__yBFfN {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

.Home_orderId__NVcbx {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    text-align: left;
}

.Home_topOH__2I0zU {
    padding: 0 0 0 30px;
}

.Home_date__3SdrZ {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    text-align: left;
    margin: 0 0 0 10px;
}

.Home_medicines__1yIsJ {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

@media only screen and (max-width: 768px) {
    .Home_body__2UfUW {
      padding: 10% 20px 10% 20px;
      width: 85%;
    }

    .Home_outerBody__1MyUr {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
    }

    .Home_titleSectionText__Z7CQR {
        font-size: 20px;
    }

    .Home_lastOrderDiv__2LDYC {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 50px;
    }

    .Home_covidEssential__1EL5N {
        font-size: 14px;
        padding: 21px 120px 50px 20px;
    }

    .Home_brSpan__1hvqe {
        /* display: none; */
    }

    .Home_titleSectionButton__2ejH8 {
        font-size: 16px;
        padding: 15px 20px 15px 20px;
    }

    .Home_covidEssentialsDiv__2Cb3T {
        flex-direction: column;
    }

    .Home_covidEssential__1EL5N {
        margin: 20px 0 20px 0;
        padding: 20px 108px 80px 40px;
        width: 50%;
    }

    .Home_covidarrow__fH6-Y {
        width: 25px;
        margin: 20px 0 0 0;
    }

    .Home_mask__6uUWw, .Home_sanitizer__20vL_, .Home_handwash__1fci8 {
        width: 120px;
        margin: 0px 0 0 150px;
        position: absolute;
    }
    
    .Home_searchDiv__2igrF {
        width: 89%;
    }

    .Home_bookApptDiv__1J8Hh {
        padding: 50px;
        flex-direction: column;
        align-items: flex-start;
    }

    .Home_plus__2k78g {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .Home_apptTitle__uFk-x {
        text-align: left;
        margin-left: 0px;
        font-size: 16px;
        width: 80%;
    }

    .Home_apptRightDiv__3vOQI {
        margin: 10px 0 10px -20px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .Home_apptRight__2so74 {
        padding: 8px 10px 8px 10px;
        width: 100px;
        line-height: 1.5;
        font-size: 14px;
    }

    .Home_plus2Div__1lcuM {
        width: 100%;

        margin-left: -45px;
    }

    .Home_apptLeft__3Mxkh {
        /* margin-left: -45px; */
        max-width: 100%;
        width: 100%;
        margin-top: -35px;
        margin-left: -20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .Home_orderStatus__1B1oI {
        flex-direction: column;
        width: 100%;
        max-width: 100%;
    }

    .Home_plus2__11_6P {
        margin-left: -170px;
    }

    .Home_orderHistoryTitle__2KJUB {
        font-size: 16px;
    }

    .Home_topOH__2I0zU {
        padding: 0;
        margin: -10px 0 15px 0px;
    }

    .Home_orderId__NVcbx {
        margin: 0 0 0 -45px;
    }

    .Home_medicines__1yIsJ {
        font-size: 10px;
        margin: -5px 0 0 -45px;
    }

    .Home_robutton__2oPBL {
        margin: 10px 25px 10px -25px;
        padding: 8px 10px 8px 10px;
        width: 100px;
        font-size: 12px;
        line-height: 1.2;
    }

    .Home_repeatOrder__3FOuQ {
        width: 100%;
        margin-left: 0px;
        padding: 0;
    }

    .Home_search__7NRlZ {
        font-size: 14px;
    }

    .Home_searchIconDiv__2r0vV {
        width: 2%;
        height: 25px;
    }

    .Home_searchicon__3QVTV {
        width: 20px;
    }

    .Home_orderHistory__3UasI {
        font-size: 12px;
    }

    .Home_orderId__NVcbx {
        margin-left: 0;
    }

    .Home_medicines__1yIsJ {
        margin-left: 0;
    }

    .Home_orderHistoryTitle__2KJUB {
        margin-left: 0;
    }

    .Home_orderHistoryTitleP__KLJkW {
        margin-left: -100px;
    }

    .Home_topOH__2I0zU {
        padding: 0 0 0 0;
    }

    .Home_nof__6GQ7Y {
        margin-left: -20px;
    }

  }



.Fold1Cards_mainCard__1oQ1N {
    display: flex;
    padding: 60px 60px 60px 60px;
    border-radius: 12px;
    box-shadow: 0 12px 42px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
}

.Fold1Cards_mainCardLeft__2f2zb {
    width: 40%;
    text-align: left;
}

.Fold1Cards_mainCardLeftTitle__CBCxq {
    font-size: 250%;
    line-height: 1;
    font-weight: 600;
}

.Fold1Cards_mainCardLeftSubtext__1RKLm {
    margin-top: 15px;
    font-size: 75%;
    font-weight: 300;
}

.Fold1Cards_mainCardRight__2ZqP6 {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Fold1Cards_mainCardRightCoupon__3vmFO {
    width: 40%;
    text-align: left;
    border-radius: 18px;
    border: dashed 2px #5b77de;
    background-color: #eff5ff;
    padding: 50px;
    font-size: 125%;
    font-weight: normal;
}

.Fold1Cards_mainCardRightCouponSpan__23oPV {
    font-weight: 600;
}

.Fold1Cards_mainCardImgDiv__1F3E_{
    position: relative;
}

.Fold1Cards_mainCardImg__3lWAW {
    position: absolute;
    bottom: -35%;
    left: -35px;
    max-width: 450px;
}

/* Safari Specific Code */
_::-webkit-full-page-media, _:future, :root .Fold1Cards_mainCardImg__3lWAW {
    position: absolute;
    bottom: 40.5%;
    left: 40%;
    width: 30%;
} 

.Fold1Cards_bottomCardDiv___uB32 {
    margin: 30px 0 30px 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.Fold1Cards_bold__336CO {
    font-weight: bolder;
}

.Fold1Cards_bottomCardLeft__2NoSd {
    margin: 0 15px 0 0;
    padding: 45px 45px 45px 45px;
    width: 50%;
    background: linear-gradient(180deg, #EFF5FF 0%, #E2E9FB 100%);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    color: #000;
}

.Fold1Cards_bottomCardCenter__3wpj7 {
    margin: 0 0 0 0;
    padding: 45px 45px 45px 45px;
    width: 50%;
    background: linear-gradient(180deg, #EFF5FF 0%, #E2E9FB 100%);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    color: #000;
}

.Fold1Cards_bottomCardTitleDiv__3fE0F {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.Fold1Cards_bottomCardTitle__3-5SB {
    font-size: 25px;
    font-weight: 400;
    color: #000;
    text-align: left;
}

.Fold1Cards_bottomCardSubtext__27U1J {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    color: #000;
    text-align: left;
    width: 90%;
}

.Fold1Cards_injection__3m_fX {
    position: absolute;
    top: auto;
    margin: -65px 0 0 -85px;
}

.Fold1Cards_bottomCardRight__zbt7n {
    position: relative;
    margin: 0 0 0 15px;
    padding: 45px 45px 45px 45px;
    width: 50%;
    background: linear-gradient(180deg, #EFF5FF 0%, #E2E9FB 100%);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    cursor: pointer;
    color: #000;
}

.Fold1Cards_capsules__2NiG1 {
    position: absolute;
    bottom: -11%;
    right: -10%;
}

@media only screen and (max-width: 1024px){
    .Fold1Cards_mainCardLeftTitle__CBCxq{
        font-size: 200%;
    }
    .Fold1Cards_mainCardRightCoupon__3vmFO{
        padding: 30px;
        font-size: 100%;
    }
    .Fold1Cards_mainCardImg__3lWAW{
        max-width: 320px;
        bottom: -50%;
        left: -45px;
    }
}

@media only screen and (max-width: 768px){
    .Fold1Cards_mainCardLeftTitle__CBCxq{
        font-size: 150%;
    }
    .Fold1Cards_mainCardRightCoupon__3vmFO{
        padding: 25px;
        font-size: 83%;
    }
    .Fold1Cards_mainCardImg__3lWAW{
        max-width: 250px;
        bottom: -50%;
        left: -45px;
    }
    .Fold1Cards_injection__3m_fX{
        margin: -65px 0 0 -48px;
    }
    .Fold1Cards_capsules__2NiG1{
        bottom: -6%;
        right: -15%;
    }
}




@media only screen and (max-width: 500px) {
    .Fold1Cards_mainCard__1oQ1N {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 50px 20px;
    }

    .Fold1Cards_mainCardLeft__2f2zb {
        width: 100%;
    }

    .Fold1Cards_mainCardLeftTitle__CBCxq {
        font-size: 22px;
    }
    
    .Fold1Cards_mainCardLeftSubtext__1RKLm {
        margin-top: 10px;
        font-size: 10px;
    }
    
    .Fold1Cards_mainCardRight__2ZqP6 {
        margin-top: 40px;
        width: 100%;
    }
    
    .Fold1Cards_mainCardRightCoupon__3vmFO {
        width: 100%;
        padding: 20px 20px 70px 20px;
    
        font-size: 16px;
    }
    
    .Fold1Cards_mainCardRightCouponSpan__23oPV {
        font-weight: 600;
    }

    .Fold1Cards_mainCardImgDiv__1F3E_ {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .Fold1Cards_mainCardImg__3lWAW {
        bottom: -241px;
        left: auto;
        right: auto;
        width: 70%;
    }


    .Fold1Cards_bottomCardDiv___uB32 {
        margin: 60px 0 60px 0;
        display: flex;
        flex-direction: row;
    }

    .Fold1Cards_bottomCardCenter__3wpj7 {
        padding: 30px 12px 30px 12px;
        text-decoration: none;
        cursor: pointer;
    }
    
    .Fold1Cards_bottomCardLeft__2NoSd {
        padding: 30px 12px 30px 12px;
        margin: 0 8px 0 0;
        text-decoration: none;
        cursor: pointer;
    }
    
    .Fold1Cards_bottomCardTitleDiv__3fE0F {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .Fold1Cards_bottomCardTitle__3-5SB {
        font-size: 14px;
    }
    
    .Fold1Cards_bottomCardSubtext__27U1J {
        display: none;
    }
    
    .Fold1Cards_injection__3m_fX {
        transform: rotate(-290deg);
        margin: 8% 0 0 20px;
        display: none;
    }
    
    .Fold1Cards_bottomCardRight__zbt7n {
        padding: 30px 12px 30px 12px;
        cursor: pointer;
        margin: 0 0 0 8px;
    }

    .Fold1Cards_bottomCardRightArrow__kJbUR {
        width: 25px;
        margin: 50px 0 0 0;
        display: none;
    }

    .Fold1Cards_bottomCardUpArrow__2Qqj0 {
        width: 25px;
        margin: 50px 0 0 0;
    }
    
    .Fold1Cards_capsules__2NiG1 {
        /* top: 94%; */
        bottom: 15%;
        left: 58%;
        width: 50%;
        display: none;
    }
  }
.ProductCard_cardContainer__1t4fd {
  padding: 0 12px 22px 16px;
  object-fit: contain;
  margin: 15px 10px 15px 10px;
  border-radius: 22px;
  box-shadow: 0 12px 35px 0 rgba(0, 0, 0, 0.06);
  width: 200px;
  height: 316px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
}

.ProductCard_cardImg__t2JxS {
  width: 190px;
  max-height: 150px;
}

.ProductCard_productDescription__2A3GQ {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.ProductCard_priceDiv__3KvZM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
  width: 85%;

  position: absolute;
  bottom: 10px;
}

.ProductCard_price__34xye {
  font-size: 20px;
  font-weight: 500;
}

.ProductCard_requestButton__3AW8I {
  font-size: 12px;
  font-weight: 500;

  cursor: pointer;
  padding: 10px 20px 10px 20px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #e0f3f1;
}

.ProductCard_priceButton__2aBLn {
  font-size: 12px;
  font-weight: 500;

  cursor: pointer;
  padding: 10px 20px 10px 20px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #e0f3f1;
}

.ProductCard_priceButton__2aBLn:hover {
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
  background-color: #2eafa3;
  color: #fff;
}

.ProductCard_discount__3TTSX {
  font-size: 12px;
  font-weight: 500;
  color: #14897e;
}

.ProductCard_hMarker__3lZLY {
  position: absolute;
  top: 5%;
  right: 5%;
  background: #2eafa3;
  border-radius: 50%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  padding: 2px 5px;
  box-shadow: 0px 12px 42px rgb(0 0 0 / 9%);
}
@media only screen and (max-width: 500px) {
  .ProductCard_cardContainer__1t4fd {
    width: 150px;
  }

  .ProductCard_price__34xye {
    font-size: 18px;
  }

  .ProductCard_discount__3TTSX {
    font-size: 12px;
  }

  .ProductCard_productDescription__2A3GQ {
    font-size: 9px;
  }

  .ProductCard_priceButton__2aBLn {
    font-size: 9px;
    padding: 10px 15px 10px 15px;
  }

  .ProductCard_cardImg__t2JxS {
    width: 110px;
  }
}

.Footer_footer__1w0lV {
    position: relative;
    z-index: 1500;
    padding: 5vh 50px;
    background-color: #4663d0;
}

.Footer_divblock23__2FFCO {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}
  
.Footer_divblock24__3hejp {
    display: none;
    margin-bottom: 20px;
}

.Footer_divblock25__2ghzt {
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
}
  
.Footer_divblock26__32wn2 {
    display: flex;
    justify-content: space-around;
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
}

.Footer_textblock24__NEyJV {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
}
  
.Footer_textblock25__KRvN6 {
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
}

.Footer_textblock26__1s9G1 {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
}

.Footer_link__Xabb2 {
    text-decoration: none;
}
  
.Footer_textblock27__1x3D1 {
    margin-right: 10px;
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.Footer_textblock39__13M5R {
  font-size: 14px;
}



@media only screen and (max-width: 420px) {
    .Footer_footer__1w0lV {
        padding: 5vh 25px;
        display: flex;
        align-items: center;
        /* width: 100%; */
    }
}

.FixedFooter_fixedfooter__KsN4k {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 1000;
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
  }
  
  .FixedFooter_textblock23__1eEfO {
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.11);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .FixedFooter_link__LbKXW {
    color: #000;
    text-decoration: none;
  }
  
  .FixedFooter_divblock22__3-kkv {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.11);
  }
  
  .FixedFooter_image11__u2wJN {
    width: 20px;
  }
  
  .FixedFooter_image12__17urt {
    width: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .FixedFooter_image13__20lJA {
    width: 20px;
  }
  
  .FixedFooter_linkblock__30xjp {
    cursor: pointer;
    display: inline-block;
  }
  
  .FixedFooter_linkblock2__15n9k {
    cursor: pointer;
    display: inline-block;
  }
  
  .FixedFooter_linkblock3__12gxt {
    cursor: pointer;
    display: inline-block;
  }
.AppointmentNotification_apptNotificationDiv__1aZJt {
    border-radius: 24px;
    background-color: #f2dcde;
    display: flex;
    padding: 26px;
    align-items: center;
}

.AppointmentNotification_notifTitleDiv__1md-0 {
    width: 20%;
    border-right: 2px dashed black;
}

.AppointmentNotification_notifTextDiv__1J7IM {
    width: 60%;
}


.AppointmentNotification_notifTitle__1qCXa {
    width: 136px;
    margin: 4px 53px 3px 23px;
    font-family: 'Poppins';
    text-align: left;
    font-size: 125%;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000000;
}

.AppointmentNotification_notifText__3Tevg {
    margin: 9px 0 0 16px;
    font-family: Poppins;
    font-size: 85%;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-align: left;
}

@media only screen and (max-width: 1024px){
    .AppointmentNotification_notifTitle__1qCXa{
        font-size: 110%;
    }
}

@media only screen and (max-width:768px){
    .AppointmentNotification_notifTitleDiv__1md-0{
        width: 27%;
    }
    .AppointmentNotification_calendar__iDKOV{
        width: 25px;
    }
}

@media only screen and (max-width:425px){
    .AppointmentNotification_calendarIconDiv__1MSdx{
        width: 20%;
    }
}

@media only screen and (max-width: 500px) {
    .AppointmentNotification_apptNotificationDiv__1aZJt {
        flex-direction: column;
        padding: 15px;
        align-items: flex-start;
    }

    .AppointmentNotification_calendarIconDiv__1MSdx {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin: 10px 0 10px 20px;
    }
    
    .AppointmentNotification_notifTitleDiv__1md-0 {
        width: 100%;
        border-right: none;
        display: flex;
        align-items: flex-start;
        margin: 10px 0 10px 20px;
    }
    
    .AppointmentNotification_notifTextDiv__1J7IM {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin: 10px 0 10px 20px;
    }
    
    
    .AppointmentNotification_notifTitle__1qCXa {
        margin: 0px 20px 0px 0px;
        font-family: 'Poppins';
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.2;
        color: #000000;
    }
    
    .AppointmentNotification_notifText__3Tevg {
        margin: 0px 20px 0px 0px;
        font-family: 'Poppins';
        text-align: left;
        font-size: 10px;
        color: #000000;
    }
}
.PrescriptionUpload_container__1v43i {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.PrescriptionUpload_body__2tEDQ {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 5% 15% 5% 15%;
    height: 67vh;
}

.PrescriptionUpload_left__3w7We {
    width: 100%;
}

.PrescriptionUpload_right__6ZOxI {
    padding: 0 50px 0 80px;
}

.PrescriptionUpload_innerDiv__CCAXY {
    border-radius: 18px;
    background-color: #f3f5ff;
    padding: 50px 20px 50px 20px;

}

.PrescriptionUpload_innerDivTick__W0n7o {
    border-radius: 18px;
    background-color: rgba(36, 178, 165, 0.1);
    padding: 50px;
}

.PrescriptionUpload_crossDiv__1CFw3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: -20px 20px 0 10px;
}

.PrescriptionUpload_uploadDiv__3JpUB {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 30px 0 30px;
}

.PrescriptionUpload_uploadTitleDiv__f8LFF {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
}

.PrescriptionUpload_uploadImageDiv__1yABg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.PrescriptionUpload_browseButtonDiv__1Hokf {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
}

.PrescriptionUpload_uploadTitle__2Aa8U {
    width: 40%;
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    text-align: left;
    margin: 0 0 0 20px;
}

.PrescriptionUpload_browseButtonText__1s5Ed {
    text-align: left;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 300;
    margin: 10px 0 0 0;
}

.PrescriptionUpload_browseDiv___Z8bO {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.PrescriptionUpload_browseButton__3lEYk {
    margin: 10px 0 0 0;
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    color: white;
    width: 80px;
}

.PrescriptionUpload_proceed__2AJRU {
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    color: white;
    width: 45%;
    text-decoration: none;
}

.PrescriptionUpload_proceedDiv__25Hfy {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.PrescriptionUpload_orDiv__3Idsq {
    margin: 15px 10px 15px 10px;
}

.PrescriptionUpload_takePicButton__3zYbn {
    margin: 10px 0 0 0;
    padding: 10px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    border-radius: 10px;
    box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
    background-color: #2eafa3;
    cursor: pointer;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    color: white;
    width: 160px;
}

.PrescriptionUpload_camDiv__2Al0f {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    width: 100vw; height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f5ff;
}

input[type="file"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    width: 125px;
    margin: 0 0 0 -30px;
}

.PrescriptionUpload_filenamediv__3DyAx {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    margin: 0 0 30px 0;
    width: 60%;
}

.PrescriptionUpload_filename__31c2f {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 0 10px;
}

.PrescriptionUpload_bcross__1I-dn {
    cursor: pointer;
}

.PrescriptionUpload_dragndrop__UBElz {
    width: 150%;
}

.PrescriptionUpload_rightTitleDiv__3RUPs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PrescriptionUpload_rightTitle__DljvN {
    font-family: "Poppins";
    text-align: left;
    font-weight: 600;
    font-size: 30px;
}

.PrescriptionUpload_btm__GW83z {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PrescriptionUpload_bottomCall__1f8BB {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
    border-radius: 25.5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    padding: 10px 20px 10px 20px;
}

.PrescriptionUpload_number__1jIdh {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;  
    color: #4663D0;
    text-decoration: none;
    cursor: pointer;
}


@media only screen and (max-width: 500px) {
    .PrescriptionUpload_body__2tEDQ {
        padding: 15% 8% 10% 8%;
    }

    .PrescriptionUpload_uploadDiv__3JpUB {
        margin: 20px 0 0 0;
        flex-direction: column;
        align-items: flex-start;
    }

    .PrescriptionUpload_uploadTitle__2Aa8U {
        font-size: 22px;
    }

    .PrescriptionUpload_dragndrop__UBElz {
        width: 100%;
    }

    .PrescriptionUpload_uploadTitleDiv__f8LFF {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 0 0 20px;
    }

    .PrescriptionUpload_uploadImageDiv__1yABg {
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 30px 0 0 0;
    }
    
    .PrescriptionUpload_browseDiv___Z8bO {
        width: 100%;
        margin: 0 0 40px 0;
    }

    .PrescriptionUpload_proceedDiv__25Hfy {
        margin: 0 0 40px 0;
        align-items: center;
        justify-content: center;
    }

    .PrescriptionUpload_browseButtonDiv__1Hokf {
        width: 100%;
    }
}
.Status_container__23fRj {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.Status_body__No4aq {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.Status_back__YrhEe {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.Status_backicon__cT5Mt {
    width: 100%;
}

.Status_innerBody__TiJyX {
    align-self: flex-end;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 92%;
}

.Status_left__-TNq3 {
    width: 30%;
}

.Status_lTop__2wtuT {
    /* height: 45vh; */
    margin: 0 20px 20px 0;
    padding: 40px 40px 0px 40px;
    background: #F3F5FF;
    border-radius: 18px;
}

.Status_lTopTitle__3h0gq {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
}

.Status_lTopSubtitle__17SA3 {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    margin: 20px 0 0 0;
}

.Status_right__2TU3V {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 0 20px 0;
    padding: 20px 70px 25px 70px;
    background: #FFFFFF;
    box-shadow: 0px 10px 41px rgba(0, 0, 0, 0.0901169);
    border-radius: 18px;
}

.Status_text__1Cf3c {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.78px;
    color: #000000;
    margin: 0 0 -40px 0;
}

.Status_profile__3mjzo {
    margin: 100px 0 0 0;
}

.Status_done__12nT4 {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 25px 10px 25px;
    margin: 40px 0 0 0;
    cursor: pointer;
    text-decoration: none;
}

.Status_submitted__35TEy {
    margin: -40px 0 0 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.78px;
    color: #2EAFA3;
}

.Status_profile2__3sjSO {
    width: 120%;
    position: relative;
    bottom: 0;
    right: -80px;
}

.Status_tablet__VqvtG {
    position: absolute;
    right: 7.5%;
    top: 35%;
}

@media only screen and (max-width: 500px) {
    .Status_body__No4aq {
        padding: 10% 8% 10% 8%;
    }

    .Status_back__YrhEe {
        left: 12%;
        top: 18%;
    }

    .Status_innerBody__TiJyX {
        flex-direction: column;
    }

    .Status_lTopSubtitle__17SA3 {
        margin-bottom: 20px;
    }

    .Status_left__-TNq3 {
        margin: 100px 0 0 0;
        width: 100%;
    }

    .Status_right__2TU3V {
        width: 90%;
        padding: 40px 10px 45px 10px;
    }

    .Status_text__1Cf3c {
        margin: 0 0 -15px 0;
        z-index: 1;
        font-size: 12px;
    }

    .Status_submitted__35TEy {
        margin: -10px 0 0 0;
        font-size: 22px;
        z-index: 1;
    }

    .Status_statusill__1n1Hx {
        width: 60%;
    }

    .Status_profile__3mjzo {
        margin-top: 40px;
    }

    .Status_done__12nT4 {
        font-size: 14px;
        padding: 10px 20px 10px 20px;
    }

    .Status_profile2__3sjSO {
        width: 100%;
        right: 0;
    }
}
.Cart_container__n9zxW {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: #000;
}

.Cart_body__2muzs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2% 15% 5% 15%;
  margin: 0 0 0 0;
}

.Cart_promo__RkKo6 {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin: 0 20px 0 0;
}

.Cart_dt__3C7uJ {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.0833333px;
  color: #4663d0;
  margin: 0 40px 20px 0;
}

.Cart_headDiv__8fV9q {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.Cart_title__1_2px {
  text-align: left;
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
}

.Cart_subtitle__2Rk85 {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  margin: 10px 0 20px 0;
}

.Cart_cartBody__1AF2O {
  width: 100%;
  display: flex;
}

.Cart_leftDiv__1LGFk {
  width: 60%;
  padding: 20px 0 20px 0;
}

.Cart_list__2uplQ {
  margin: -20px 0 0 0;
}

.Cart_rightDiv__2BaUS {
  width: 40%;
  padding: 20px 20px 20px 20px;
}

.Cart_summaryCard__BGHpK {
  padding: 35px;
  border-radius: 18px;
  box-shadow: 0 6px 53px 0 rgba(0, 0, 0, 0.06);
}

.Cart_horDiv__1u3vX {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  border-bottom: solid 1px #24b2a640;
  font-family: "Poppins";
  font-size: 14px;
}

.Cart_shpDiv__A6kFy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
}

.Cart_tbpDiv__2Mvn- {
  border-radius: 8px;
  background-color: #2eafa225;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
}

.Cart_tbp__2Mgh0 {
  font-weight: 600;
  color: #2eafa3;
}

.Cart_total__1vGjb {
  font-weight: 600;
}

.Cart_butDiv__1Igre {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 10px 10px 0;
}

.Cart_button__14HZ8 {
  width: 145px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
  background-color: #2eafa3;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;

  cursor: pointer;
}

.Cart_lBottom__1eXev {
  background: #ffeceb;
  border-radius: 18px;
  padding: 25px 25px 25px 25px;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Cart_lBottomTitle__31fCo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}

.Cart_lBottomSubtitle__3qtHn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #4663d0;
}

.Cart_lBottomPhone__278TA {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
  border-radius: 25.5px;
  padding: 10px 20px 10px 20px;
  margin: 10px 0 0 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;

  color: #000000;
}

.Cart_phoneDiv__1Ld0m {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Cart_promoDiv__2eKNk {
  margin: 20px 0 0 0;
}

.Cart_promo__RkKo6 {
  border: none;
  outline: none;
  padding: 15px 25px 15px 25px;
  border-radius: 8px;
  box-shadow: 0 6px 53px 0 rgba(0, 0, 0, 0.06);
}
.Cart_shpDiv__A6kFy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
}
.Cart_tbpDiv__2Mvn- {
  border-radius: 8px;
  box-shadow: 1px 10px 50px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
  margin: 10px 0 0 0;
}

.Cart_tbp__2Mgh0 {
  font-weight: 600;
  color: #2eafa3;
}

@media only screen and (max-width: 500px) {
  .Cart_body__2muzs {
    padding: 20% 20px 10% 20px;
    flex-direction: column;
  }

  .Cart_cartBody__1AF2O {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Cart_title__1_2px {
    margin: 0 0 0 5%;
    font-size: 22px;
  }

  .Cart_subtitle__2Rk85 {
    margin: 5px 0 20px 5%;
    text-align: left;
    font-style: italic;
    font-size: 12px;
  }

  .Cart_headDiv__8fV9q {
    margin: 0 0 0 20px;
  }

  .Cart_leftDiv__1LGFk {
    width: 90%;
  }

  .Cart_rightDiv__2BaUS {
    width: 90%;
  }

  .Cart_button__14HZ8 {
    font-size: 14px;
    width: 100px;
    height: 35px;
  }

  .Cart_horDiv__1u3vX,
  .Cart_shpDiv__A6kFy,
  .Cart_tbpDiv__2Mvn- {
  }
}

.Cart_promoDescription__3fQH9 {
  font-weight: 300;
  color: #2eafa3;
  font-size: 13px;
}

.CartItem_cartItemContainer__W3Fy8 {
  padding: 17px 29px 16px;
  object-fit: contain;
  border-radius: 18px;
  background-color: #f3f5ff;
  margin: 20px 0 20px 0;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.CartItem_originalPrice__pTZmd {
  margin-right: 10px;
  font-size: 12px;
  text-decoration: line-through;
  opacity: 0.6;
}

.CartItem_left__3AWi2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.CartItem_name__SFwOf {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.CartItem_nameRow__1Y7QE {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CartItem_quantityModifierW__3u6z2 {
  opacity: 0.6;
}

.CartItem_isPrescriptionDrug__2Mw_H {
  margin: 0 0 0 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 70%;
  letter-spacing: -0.3px;
  color: #ffffff;

  background: linear-gradient(152.43deg, #748bdf 24.65%, #5874de 98.71%);
  box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.509151);
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
}

.CartItem_description__xG_BY {
  font-family: "Poppins";
  font-size: 10px;
  margin: 5px 0 5px 0;
}

.CartItem_del__3C8Xs {
  margin: 10px 0 0 0;
}

.CartItem_right__32bJj {
  display: flex;
}

.CartItem_price__1JWf1 {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  color: #24b2a5;
}

.CartItem_inputgroup__72sbo {
  background-color: transparent;
  background-color: #fff;
  padding: 4px 0 4px 0;
  border-radius: 10px;
}

.CartItem_minus__2cLvc {
  border: none;
  background-color: transparent;
  padding: 2px 10px 2px 10px;
  text-align: center;
  cursor: pointer;
}

.CartItem_val__3TIPg {
  width: 20px;
  text-align: center;
  border: none;
  padding: 2px 8px 2px 9px;
  border-radius: 7px;
  background-color: #f3f5ff;
  margin: 0 5px 0 5px;

  font-family: "Poppins";
  font-size: 14px;
  color: #24b2a5;
}

.CartItem_plus__1j1sy {
  border: none;
  background-color: transparent;
  padding: 2px 10px 2px 10px;
  text-align: center;
  cursor: pointer;
}

.CartItem_top__Ttl9k {
  display: flex;
  justify-content: space-between;
}

.CartItem_bottom__1l4dQ {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CartItem_bottomW__3GuHK {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 500px) {
}

.ViewAll_container__31Mcv {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.ViewAll_body__2ZPV5 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 20% 5% 20%; 
}

.ViewAll_bottomButtons__2Yj-J {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 0 0;
}

.ViewAll_back__vx8Ty {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.ViewAll_backicon__3lR_D {
    width: 100%;
}

.ViewAll_viewAllDiv__2Ac85 {
    margin: 40px 0 0 0;
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: auto auto auto;
}

.ViewAll_gridItem__23IoJ {

}

.ViewAll_headDiv__3tUkW {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 30px 0 0 0;
}

.ViewAll_head__22ZUG {
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 20px 0;
}

.ViewAll_subtitle__3cIOP {
    text-align: left;
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.ViewAll_searchIconDiv__lZgVB {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.3);
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width: 500px) {
    .ViewAll_body__2ZPV5 {
      padding: 10% 8% 10% 8%;
    }

    .ViewAll_viewAllDiv__2Ac85 {
        margin: 20px 0 0 -20px;
        display: grid;
        grid-column-gap: 0px;
        grid-row-gap: 10px;
        grid-template-columns: auto auto;
    }

    .ViewAll_back__vx8Ty {
        left: 8%;
        top: 18%;
    }

    .ViewAll_headDiv__3tUkW {
        margin: 10px 0 0 0;
    }
}
.Description_container__1lNUG {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.Description_body__1Y7dX {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 20% 5% 20%; 
}

.Description_back__SuGYQ {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.Description_backicon__3ANKi {
    width: 100%;
}

.Description_headDiv__2eS9Q {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.Description_head__sDZbf {
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 20px 0;
}

.Description_subtitle__Q_l3O {
    text-align: left;
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

.Description_descDiv__1A-xB {
    margin: 30px 0 0 0;
}

.Description_section1__18ncV {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.Description_imgDiv__3BpHo {
    margin: 0 40px 0 0;
}

.Description_cardImg__2mpgM {

}

.Description_name__2ZOF4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    text-align: left;
    margin: 40px 0 0 0;
}

.Description_section__Mc4ps {
    margin: 25px 0 25px 0;
}

.Description_sectionTitle__2xNhO {
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.Description_sectionBody__3jsEY {
    margin: 5px 0 0 0;
    text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
}

.Description_footer__2zMMu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.Description_buttonsDiv__1BwhM {
    display: flex;
}

.Description_addToCart__36VtJ {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;

    margin: 0 10px 0 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}

.Description_buyNow__1w6qP {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.3);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;

    margin: 0 10px 0 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}

.Description_priceDiv__3Asa3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;
    width: 100%;
}

.Description_discount__2eTJC {
    font-size: 12px;
    font-weight: 500;
    color: #14897e;
}

.Description_requestButton__35QLP {
    font-size: 12px;
    font-weight: 500;

    cursor: pointer;
    padding: 10px 20px 10px 20px;
    object-fit: contain;
    border-radius: 10px;
    background-color: #e0f3f1;
}

.Description_price__3JAYG {
    font-size: 20px;
    font-weight: 800;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 10px 0 0 0;
}

.Description_ppiece__yQwEx {
    font-weight: normal;
    font-size: 16px;
    margin: 5px 0 0 5px;
}

.Description_discount__2eTJC {
    font-size: 12px;
    font-weight: 500;
    color: #14897e;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 2px 0 0 0;
}

@media only screen and (max-width: 500px) {
    .Description_body__1Y7dX {
      padding: 10% 8% 10% 8%;
    }

    .Description_imgDiv__3BpHo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .Description_section1__18ncV {
        flex-direction: column;
    }

    .Description_back__SuGYQ {
        left: 8%;
        top: 18%;
    }

    .Description_cardImg__2mpgM {
        max-width: 100%;
    }

    .Description_headDiv__2eS9Q {
        margin: 100px 0 0 0;
    }
}
.OrderHistory_container__3hl5F {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.OrderHistory_body__EWneF {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.OrderHistory_back__WVb5M {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.OrderHistory_backicon__1WYBN {
    width: 100%;
}

.OrderHistory_innerBody__3Wxh7 {
    align-self: flex-end;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    width: 92%;
}

.OrderHistory_left__q3zMq {
    width: 30%;
}

.OrderHistory_lTop__3o95Y {
    /* height: 45vh; */
    margin: 0 20px 20px 0;
    padding: 40px 40px 0px 40px;
    background: #F3F5FF;
    border-radius: 18px;
}

.OrderHistory_lTopTitle__3U4yV {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
}

.OrderHistory_lTopSubtitle__2jcV9 {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    margin: 20px 0 0 0;
}

.OrderHistory_lBottom__31uqL {
    background: #FFECEB;
    border-radius: 18px;
    padding: 25px 25px 25px 25px;
    margin: 0 20px 20px 0;
}

.OrderHistory_lBottomTitle__33WCl {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
}

.OrderHistory_lBottomSubtitle__1qAv_ {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #4663D0;
}

.OrderHistory_lBottomPhone__26HLg {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
    border-radius: 25.5px;
    padding: 10px 20px 10px 20px;
    margin: 10px 0 0 0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;

    color: #000000;

}

.OrderHistory_phoneDiv__1rg6K {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.OrderHistory_right__BYo9c {
    width: 70%;
    padding: 70px 70px 70px 70px;
    background: #FFFFFF;
    box-shadow: 0px 10px 41px rgba(0, 0, 0, 0.0901169);
    border-radius: 18px;
}

.OrderHistory_prescription__3hFyu {
    margin: 30px 0 0 0;
}

.OrderHistory_prescription2__3mqr9 {
    width: 100%;
    position: relative;
    bottom: 0px;
    right: -100px;
}

/** ORDER HISTORY CARD STYLES */

.OrderHistory_cardContainer__3KsVG {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 30px 10px 30px;
    padding: 15px 30px 15px 30px;
    background: #FBFBFB;
    border-radius: 10px;    
}

.OrderHistory_cardLeft__12_mY {
}

.OrderHistory_orderId__3VAvY {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: left;
    letter-spacing: -0.116667px;
    color: #000000;
    opacity: 0.4;
}

.OrderHistory_date__SVcQL {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.116667px;
    color: #000000;
    text-align: left;
    margin: 5px 0 0 0;
}

.OrderHistory_productsList__3TuxU {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
}

.OrderHistory_product__8gvaE {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.0833333px;
    color: #000000;
    text-align: left;
    margin: 5px 5px 0 0;
}

.OrderHistory_cardRight__1yxN6 {
    display: flex;
    align-items: center;
}

.OrderHistory_price__3Qobn {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    text-align-last: left;
    color: #24B2A5;
    padding: 10px 25px 10px 25px;
}

.OrderHistory_reorder__1FF6B {
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 1px 51px rgba(79, 105, 206, 0.2);
    border-radius: 6px;
    padding: 10px 25px 10px 25px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    
    text-decoration: none;
    cursor: pointer;
}

.OrderHistory_tablet__2uAAl {
    position: absolute;
    right: 7.5%;
    top: 35%;
}

@media only screen and (max-width: 500px) {
    .OrderHistory_body__EWneF {
      padding: 10% 8% 10% 8%;
    }
    
    .OrderHistory_innerBody__3Wxh7 {
        flex-direction: column;
    }

    .OrderHistory_prescription2__3mqr9 {
        right: 0;
    }

    .OrderHistory_left__q3zMq {
        margin: 100px 0 0 0;
        width: 100%;
    }

    .OrderHistory_right__BYo9c {
        padding: 0;
        width: 93%;
    }

    .OrderHistory_back__WVb5M {
        top: 18%;
    }

    .OrderHistory_cardContainer__3KsVG {
        flex-direction: column;
        margin: 15px 15px 15px 15px;
        padding: 15px 15px 15px 15px;
    }

    .OrderHistory_cardLeft__12_mY {
        margin-left: 0;
        width: 100%;
    }

    .OrderHistory_cardRight__1yxN6 {
        margin: 30px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .OrderHistory_price__3Qobn {
        padding: 10px 0px 10px 0px;
    }
}
.OrderPlaced_container__3WkGo {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.OrderPlaced_body__1AZds {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 3% 10% 5% 10% ;
}

.OrderPlaced_back__3Mm9b {
    display: none;
    position: absolute;
    top: 19.5%;
    left: 10%;
}

.OrderPlaced_backicon__jwQfA {
    width: 100%;
}

.OrderPlaced_innerBody__3Whb4 {
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    background: #24B2A525;
    mix-blend-mode: normal;
    box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.136937);
    border-radius: 18px;
    padding: 40px 70px 20px 70px;
}

.OrderPlaced_lTitle__1x2DY {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.78px;
    text-align: left;
    color: #000000;
}

.OrderPlaced_lSubtitle__3ee_S {
    margin: 10px 0 15px 0;
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.0833333px;
    text-align: left;
    color: #000000;
}

.OrderPlaced_whiteCard__tzbh5 {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
}

.OrderPlaced_topSection__2qrIN {
    padding: 0 0 15px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.28);
}

.OrderPlaced_row__24YBb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.OrderPlaced_lText__2KN9m {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: left;
    letter-spacing: -0.116667px;
    color: #000000;
    
}

.OrderPlaced_rText__2xCwK {
    margin: 5px 0 5px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.116667px;
    color: #000000;
    text-align: right;
    
}

.OrderPlaced_lTextG__rFio1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: left;
    letter-spacing: -0.116667px;
    color: #2EAFA3;
    
}

.OrderPlaced_rTextG__UQRps {
    margin: 5px 0 5px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.116667px;
    color: #2EAFA3;
    text-align: right;
    
}

.OrderPlaced_bottom__Q7uYg {
    margin: 10px 0 0 0;
}

.OrderPlaced_inclusive__1QDgv {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    font-size: 10px;
    line-height: 32px;
    text-align: left;
    color: #7A7A7A;
    margin: -15px 0 10px 0;
}

.OrderPlaced_brownBox__25lI- {
    background: #FFECEB;
    border-radius: 18px;
    padding: 25px 25px 25px 25px;
    margin: 15px 0 0 0;
}

.OrderPlaced_bottomText__1b0se {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 32px;
    color: #4663D0;
    
}

.OrderPlaced_lBottomTitle__ZLca4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
}

.OrderPlaced_lBottomSubtitle__3nnfJ {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #4663D0;
}

.OrderPlaced_lBottomPhone__dNas3 {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
    border-radius: 25.5px;
    padding: 10px 20px 10px 20px;
    margin: 10px 0 0 0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;

    color: #000000;

}

.OrderPlaced_phoneDiv__jt8u5 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.OrderPlaced_right__2sb7I {
    padding: 30px 80px 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.OrderPlaced_backButton__8kql0 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    text-decoration: none;
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    margin: 30px 0 0 0;
    cursor: pointer;
}


@media only screen and (max-width: 500px) {
    .OrderPlaced_body__1AZds {
      padding: 10% 8% 10% 8%;
    }

    .OrderPlaced_innerBody__3Whb4 {
        margin: 20px 0 0 0;
        padding: 40px 20px 20px 20px;
        flex-direction: column;
        width: 90%;
    }

    .OrderPlaced_back__3Mm9b {
        display: none;
    }

    .OrderPlaced_lTitle__1x2DY {
        font-size: 22px;
        line-height: 1.2;
    }

    .OrderPlaced_lSubtitle__3ee_S {
        font-size: 12px;
    }

    .OrderPlaced_right__2sb7I {
        padding: 30px 20px 30px 20px;
    }
}
.Profile_container__WdMDr {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.Profile_body__2sBMO {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.Profile_back__uhDPM {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.Profile_backicon__14uqr {
    width: 100%;
}

.Profile_innerBody__QETL0 {
    align-self: flex-end;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    width: 92%;
}

.Profile_left__3DUFg {
    width: 30%;
}

.Profile_lTop__l5OCj {
    /* height: 45vh; */
    margin: 0 20px 20px 0;
    padding: 40px 40px 0px 40px;
    background: #F3F5FF;
    border-radius: 18px;
}

.Profile_lTopTitle__28K_5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
}

.Profile_lTopSubtitle__1aRT2 {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    margin: 20px 0 0 0;
}

.Profile_right__3n7lg {
    width: 70%;
    padding: 20px 70px 25px 70px;
    background: #FFFFFF;
    box-shadow: 0px 10px 41px rgba(0, 0, 0, 0.0901169);
    border-radius: 18px;
}

.Profile_profile__39xAB {
    margin: 100px 0 0 0;
}

.Profile_profile2__2W4lk {
    width: 120%;
    position: relative;
    bottom: 0;
    right: 0;
}

.Profile_edit__2j79y {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    cursor: pointer;
}

.Profile_editText__1gF56 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px; 
    -webkit-text-decoration-line: underline; 
            text-decoration-line: underline;
    color: #4663D0;
    margin: 0 10px 0 0;
    mix-blend-mode: normal;
}  

.Profile_details__31qx7 {
    margin: 50px 0 0 0;
}

.Profile_row__2hy3i {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 0 15px 0;
}

.Profile_rowLeft__2mG1R {
    width: 150px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
}

.Profile_rowRight__HrxQa {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: left;  
    color: #000000;   
    mix-blend-mode: normal;
    opacity: 0.5;
}

.Profile_pincode__3byPd {
    background: #5D78DE;
    border-radius: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 5.48333px;
    color: #FFFFFF;  
    text-align: center;
    padding: 10px 10px 10px 12px;  
    margin: 0 25px 0 0;
}

.Profile_save__2wXF4 {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 8px 20px 8px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.Profile_saveDiv__2oC9f {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 30px 0 0 0;
}

.Profile_blankDiv__3tah4 {
    height: 75px;
}

.Profile_emailI__3fpY7, .Profile_phoneI__2vJ_N, .Profile_nameI__1ARSt {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 15px 10px 15px;
    margin: 0 0px 0 0px;
    width: 30%;
}

.Profile_pincodeI__2JtRl {
    background: #5D78DE;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 0 0 0 0;
    width: 85px;
    text-align: left;

    letter-spacing: 5.48333px;
    color: #FFFFFF;
}

.Profile_pincodeI__2JtRl::-webkit-input-placeholder {
    color: white;
  }

.Profile_pincodeI__2JtRl:-ms-input-placeholder {
    color: white;
  }

.Profile_pincodeI__2JtRl::-ms-input-placeholder {
    color: white;
  }

.Profile_pincodeI__2JtRl::placeholder {
    color: white;
  }

.Profile_pinDiv__1BdMo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Profile_addressI__wSqKy {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 10px 0 0 0;
    width: 350px;
}

.Profile_addDiv__UMA5R {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 500px) {
    .Profile_body__2sBMO {
      padding: 10% 8% 10% 8%;
    }

    .Profile_innerBody__QETL0 {
        flex-direction: column;
    }

    .Profile_left__3DUFg {
        margin: 100px 0 0 0;
        width: 100%;
    }

    .Profile_right__3n7lg {
        width: 80%;
        padding: 20px 20px 25px 20px;
    }

    .Profile_rowLeft__2mG1R {
        font-size: 12px;
        width: 80px;
    }

    .Profile_row__2hy3i {

    }

    .Profile_addDiv__UMA5R {
        flex-direction: column;
        align-items: flex-start;
    }

    .Profile_nameI__1ARSt, .Profile_phoneI__2vJ_N, .Profile_emailI__3fpY7 {
        width: 80%;
    }

    .Profile_pincodeI__2JtRl {

    }

    .Profile_addressI__wSqKy {
        width: 70%;
    }

    .Profile_addDiv__UMA5R {
        margin: 0 0 0 -10px;
    }

    .Profile_pinDiv__1BdMo {
        margin: 0 0 0 -10px;
        width: 40%;
    }

    .Profile_rowRight__HrxQa {
        width: 100%;
    }

    .Profile_save__2wXF4 {
        font-size: 12px;
        padding: 6px 20px 6px 20px;
    }

    .Profile_back__uhDPM {
        top: 18%;
    }

    .Profile_row__2hy3i {
        flex-direction: column;
    }

    .Profile_addDiv__UMA5R {
        margin-left: 5px;
        margin-top: 5px;
    }

    .Profile_addOuterDiv__3VV76 {
        margin-left: 5px;
        margin-top: 5px;
    }

    .Profile_addressI__wSqKy {
        margin-left: -10px;
    }

    .Profile_pincode__3byPd {
        margin-bottom: 10px;
    }
}
.DeliveryDetails_container__pZQd8 {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.DeliveryDetails_body__2541a {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5% 10% 5% 10% ;
}

.DeliveryDetails_back__10SsE {
    position: absolute;
    top: 22.5%;
    left: 10%;
}

.DeliveryDetails_backicon__f1KO_ {
    width: 100%;
}

.DeliveryDetails_innerBody__36oJO {
    align-self: flex-end;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    width: 92%;
}

.DeliveryDetails_left__2FrDm {
    width: 30%;
}

.DeliveryDetails_lTop__3iUH2 {
    /* height: 45vh; */
    margin: 0 20px 20px 0;
    padding: 40px 40px 0px 40px;
    background: #F3F5FF;
    border-radius: 18px;
}

.DeliveryDetails_lTopTitle__3-lFE {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    text-align: left;
}

.DeliveryDetails_lTopSubtitle__3XuvC {
    font-family: "Poppins";
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    margin: 20px 0 0 0;
}

.DeliveryDetails_right__c6-Qu {
    width: 70%;
    padding: 20px 70px 25px 70px;
    background: #FFFFFF;
    box-shadow: 0px 10px 41px rgba(0, 0, 0, 0.0901169);
    border-radius: 18px;
}

.DeliveryDetails_profile__1gdYL {
    margin: 100px 0 0 0;
}

.DeliveryDetails_prescription__161zE {
    width: 120%;
    position: relative;
    bottom: 0;
    right: -100px;
}

.DeliveryDetails_edit__zTHe0 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    cursor: pointer;
}

.DeliveryDetails_editText__1gwgP {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px; 
    -webkit-text-decoration-line: underline; 
            text-decoration-line: underline;
    color: #4663D0;
    margin: 0 10px 0 0;
    mix-blend-mode: normal;
}  

.DeliveryDetails_details__aq0r5 {
    margin: 0 0 0 0;
}

.DeliveryDetails_row__2W_m9 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 0 15px 0;
}

.DeliveryDetails_rowLeft__2iRTi {
    width: 150px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
}

.DeliveryDetails_rowRight__3b3hD {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: left;  
    color: #000000;   
    mix-blend-mode: normal;
    opacity: 0.5;
}

.DeliveryDetails_pincode__1Z6P7 {
    background: #5D78DE;
    border-radius: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 5.48333px;
    color: #FFFFFF;  
    text-align: center;
    padding: 10px 10px 10px 12px;  
    margin: 0 25px 0 0;
}

.DeliveryDetails_save__23zg5 {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 8px 20px 8px 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.DeliveryDetails_saveDiv__3OARn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 30px 0 0 0;
}

.DeliveryDetails_blankDiv__2ymFF {
    height: 75px;
}

.DeliveryDetails_emailI__1MOex, .DeliveryDetails_phoneI___oSK6, .DeliveryDetails_nameI__2FpXV {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 15px 10px 15px;
    margin: 0 0px 0 0px;
    width: 30%;
}

.DeliveryDetails_pincodeI__7BmSi {
    background: #5D78DE;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 0 0 0 0;
    width: 85px;
    text-align: left;

    letter-spacing: 5.48333px;
    color: #FFFFFF;
}

.DeliveryDetails_pincodeI__7BmSi::-webkit-input-placeholder {
    color: white;
  }

.DeliveryDetails_pincodeI__7BmSi:-ms-input-placeholder {
    color: white;
  }

.DeliveryDetails_pincodeI__7BmSi::-ms-input-placeholder {
    color: white;
  }

.DeliveryDetails_pincodeI__7BmSi::placeholder {
    color: white;
  }

.DeliveryDetails_addressI__2AzJr {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 10px 0 0 0;
    width: 350px;
}

.DeliveryDetails_addDiv__1XzmP {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DeliveryDetails_detailsDiv__3GNbb {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0 0 0;
}

.DeliveryDetails_yourDetailsText__1tjbz {
    width: 120px;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 14px;
}

.DeliveryDetails_name__39Q81, .DeliveryDetails_phone__1qseT {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 0 10px 0 10px;
    width: 50%;
}

.DeliveryDetails_email__3NIZd {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 0 10px 0 10px;
    width: 170%;
}

.DeliveryDetails_pincode__1Z6P7 {
    background: #5D78DE;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 6px 20px 6px 20px;
    margin: 0 10px 0 10px;

    letter-spacing: 5.48333px;
    color: #FFFFFF;
}

.DeliveryDetails_pincode__1Z6P7::-webkit-input-placeholder {
    color: white;
  }

.DeliveryDetails_pincode__1Z6P7:-ms-input-placeholder {
    color: white;
  }

.DeliveryDetails_pincode__1Z6P7::-ms-input-placeholder {
    color: white;
  }

.DeliveryDetails_pincode__1Z6P7::placeholder {
    color: white;
  }

.DeliveryDetails_pinDiv__2M_EK {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
}

.DeliveryDetails_tablet__4BHBY {
    position: absolute;
    right: 7.5%;
    top: 35%;
}

.DeliveryDetails_address__3zn2E {
    background: #FFFFFF;
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 10px 20px 10px 20px;
    margin: 20px 0 0 0;
    width: 100%;
}

.DeliveryDetails_add__38wHR {
    display: flex;
    align-items: center;
    width: 50%;
}

.DeliveryDetails_addI__v0YF0 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
}

.DeliveryDetails_addDiv__1XzmP {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.DeliveryDetails_bookDiv__7i5ks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0 0 0;
}

.DeliveryDetails_book__135_t {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;

    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .DeliveryDetails_body__2541a {
      padding: 10% 8% 10% 8%;
    }

    .DeliveryDetails_innerBody__36oJO {
        flex-direction: column;
    }

    .DeliveryDetails_left__2FrDm {
        margin: 100px 0 0 0;
        width: 100%;
    }

    .DeliveryDetails_lTop__3iUH2 {
        margin-right: 10px;
    }

    .DeliveryDetails_profile__1gdYL {
        margin: 20px 0 0 0;
    }

    .DeliveryDetails_back__10SsE {
        left: 15%;
        top: 18%;
    }

    .DeliveryDetails_prescription__161zE {
        margin-top: 0;
        right: 5%;
    }

    .DeliveryDetails_right__c6-Qu {
        width: 80%;
        padding: 20px 25px 25px 25px;
    }

    .DeliveryDetails_yourDetailsText__1tjbz {
        width: 70px;
        font-size: 12px;
    }

    .DeliveryDetails_name__39Q81, .DeliveryDetails_email__3NIZd, .DeliveryDetails_phone__1qseT {
        font-size: 12px;
        margin: 0 5px 0 5px;
    }

    .DeliveryDetails_add__38wHR {
        width: 100%;
    }

    .DeliveryDetails_addDiv__1XzmP {
        font-size: 12px;
        width: 80%;
    }

    .DeliveryDetails_pinDiv__2M_EK {
        width: 50%;
    }

    .DeliveryDetails_pincode__1Z6P7 {
        font-size: 12px;
        max-width: 100%;
    }

    .DeliveryDetails_addI__v0YF0 {
        width: 100%;
    }

    .DeliveryDetails_book__135_t {
        font-size: 14px;
    }
}
.PaymentDetails_container__5H2_S {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: #000;
}

.PaymentDetails_body__1r-J7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2% 15% 5% 15%;
  margin: 0 0 0 0;
}

.PaymentDetails_promoDiv__2xhbn {
  margin: 20px 0 0 0;
}

.PaymentDetails_promo__2iaBs {
  border: none;
  outline: none;
  padding: 15px 25px 15px 25px;
  border-radius: 8px;
  box-shadow: 0 6px 53px 0 rgba(0, 0, 0, 0.06);
}

.PaymentDetails_dt__339A6 {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.0833333px;
  color: #4663d0;
  margin: 0 40px 20px 0;
}

.PaymentDetails_headDiv__PWun1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.PaymentDetails_title__20G6R {
  text-align: left;
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
}

.PaymentDetails_subtitle__3jn45 {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: normal;
  margin: 10px 0 20px 0;
}

.PaymentDetails_cartBody__3VLeI {
  width: 100%;
  display: flex;
}

.PaymentDetails_leftDiv__3rOFn {
  width: 60%;
  padding: 20px 0 20px 0;
}

.PaymentDetails_list__3eaQ2 {
  margin: -20px 0 0 0;
}

.PaymentDetails_rightDiv__1AmNQ {
  width: 40%;
  padding: 20px 20px 20px 20px;
}

.PaymentDetails_summaryCard__2r1hU {
  padding: 35px;
  border-radius: 18px;
  box-shadow: 0 6px 53px 0 rgba(0, 0, 0, 0.06);
}

.PaymentDetails_horDiv__3J3aC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  border-bottom: solid 1px #24b2a640;
  font-family: "Poppins";
  font-size: 14px;
}

.PaymentDetails_shpDiv__1wqcZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
}

.PaymentDetails_tbpDiv__Zv1Wx {
  border-radius: 8px;
  box-shadow: 1px 10px 50px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Poppins";
  font-size: 14px;
  margin: 10px 0 0 0;
}

.PaymentDetails_tbp__1ohzD {
  font-weight: 600;
  color: #2eafa3;
}

.PaymentDetails_total___vcFV {
  font-weight: 600;
}

.PaymentDetails_butDiv___4T_L {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 10px 10px 0;
}

.PaymentDetails_button__1NI-P {
  width: 145px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
  background-color: #2eafa3;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;

  cursor: pointer;
}

.PaymentDetails_buttonCOD__2K8-6 {
  width: 145px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.32);
  background-color: #2eafa3c5;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;

  cursor: pointer;
}

.PaymentDetails_lBottom__3UjVD {
  background: #ffeceb;
  border-radius: 18px;
  padding: 25px 25px 25px 25px;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PaymentDetails_lBottomTitle__2DKVx {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}

.PaymentDetails_lBottomSubtitle__20pxf {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #4663d0;
}

.PaymentDetails_lBottomPhone__3oBd0 {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
  border-radius: 25.5px;
  padding: 10px 20px 10px 20px;
  margin: 10px 0 0 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;

  color: #000000;
}

.PaymentDetails_phoneDiv__1A7zD {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/*MODAL STYLES*/

.PaymentDetails_loginModalBg__2yTCe {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.54);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PaymentDetails_loginModal__1BiNi {
  position: relative;
  padding: 50px 50px 50px 50px;
  background-color: #fff;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 550px;
}

.PaymentDetails_cross__3zKzq {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.PaymentDetails_loginLogo__k8_TU {
  width: 75px;
  margin: 0 0 10px 0;
}

.PaymentDetails_modalTitle__1dh_s {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px;
  margin: 0 0 0 0;
  text-align: center;
  width: 70%;
}

.PaymentDetails_modalSubTitle__Ecl_J {
  font-family: "Poppins";
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  opacity: 0.3;
  margin: 10px 0 0 0;
  text-align: center;
  width: 70%;
}

.PaymentDetails_proceedToPay__9pZpP {
  margin: 30px 0 0 0;
  padding: 15px 30px;
  background: #2eafa3;
  cursor: pointer;
  border-radius: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.PaymentDetails_ltablet__inKQt {
  position: absolute;
  top: -30px;
  left: 50px;
}

.PaymentDetails_rtablet__1zKhi {
  position: absolute;
  bottom: -30px;
  right: 30px;
}

@media only screen and (max-width: 500px) {
  .PaymentDetails_body__1r-J7 {
    padding: 20% 20px 10% 20px;
    flex-direction: column;
  }

  .PaymentDetails_cartBody__3VLeI {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .PaymentDetails_loginModal__1BiNi {
    width: 80vw;
    padding: 50px 10px;
  }

  .PaymentDetails_title__20G6R {
    margin: 0 0 0 5%;
    font-size: 22px;
  }

  .PaymentDetails_subtitle__3jn45 {
    margin: 5px 0 20px 5%;
    text-align: left;
    font-style: italic;
    font-size: 12px;
  }

  .PaymentDetails_headDiv__PWun1 {
    margin: 0 0 0 20px;
  }

  .PaymentDetails_promo__2iaBs {
    margin-left: -20px;
    margin-right: 10px;
    width: 100px;
  }

  .PaymentDetails_leftDiv__3rOFn {
    width: 90%;
  }

  .PaymentDetails_rightDiv__1AmNQ {
    width: 90%;
  }

  .PaymentDetails_button__1NI-P,
  .PaymentDetails_buttonCOD__2K8-6 {
    font-size: 14px;
    width: 100px;
    height: 35px;
  }

  .PaymentDetails_horDiv__3J3aC,
  .PaymentDetails_shpDiv__1wqcZ,
  .PaymentDetails_tbpDiv__Zv1Wx {
  }
}

.PaymentDetails_promo__2iaBs {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin: 0 20px 0 0;
}
.PaymentDetails_promoDescription__1ZnjT {
  font-weight: 300;
  color: #2eafa3;
  font-size: 13px;
}

.PaymentDetails_promoDiv__2xhbn {
  margin: 20px 0 0 0;
}

.PaymentDetails_promo__2iaBs {
  border: none;
  outline: none;
  padding: 15px 25px 15px 25px;
  border-radius: 8px;
  box-shadow: 0 6px 53px 0 rgba(0, 0, 0, 0.06);
}

.VideoCallAppointment_container__2qBtN {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.VideoCallAppointment_body__3X4sl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 10% 5% 10% ;
    height: 65vh;
}

.VideoCallAppointment_bluebox__1gdDt {
    height: 120%;
    width: 80%;
    background: #F3F5FF;
    border-radius: 18px;
    padding: 20px 50px 20px 50px;
    position: relative;
}

.VideoCallAppointment_crossDiv__2QA4x {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
}

.VideoCallAppointment_outerDiv__1fsjF {
    display: flex;
    height: 100%;
}

.VideoCallAppointment_leftDiv__3QHx5 {
    width: 35%;
}

.VideoCallAppointment_leftText__qTBTw {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.VideoCallAppointment_leftDivTitle__swGkU {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.65px; 
    color: #000000; 
}

.VideoCallAppointment_leftDivSub__1VF06 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.65px; 
    color: #000000; 
}

.VideoCallAppointment_leftDivFreeConsultation__2GTbU {
    margin: 10px 0 0 0;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px; 
    color: #5D78DE;
    text-align: left;
}

.VideoCallAppointment_rightDiv__25LUk {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.VideoCallAppointment_row__3xF8h {
    display: flex;
    margin: 8px 0 8px 0;
}

.VideoCallAppointment_tag__1dQUV {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
    width: 200px;
}

.VideoCallAppointment_timeslotsDiv__1_Z9p {
    display: flex;
}

.VideoCallAppointment_timeslotActive__2z2SY {
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.0973285);
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    cursor: pointer;
}

.VideoCallAppointment_timeslot__dEbD9 {
    text-align: left;
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.VideoCallAppointment_inputText__3s1oq {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 200px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.VideoCallAppointment_inputSelect__2qKGv {
    background: #5D78DE;
    box-shadow: 0px 2px 21px rgba(93, 120, 222, 0.336375);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 220px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}


.VideoCallAppointment_option__3UFyE {
    color: #ffffff;
}


.VideoCallAppointment_videocall__3bRI7 {
    position: relative;
    width: 65%;
}

.VideoCallAppointment_imgDiv__UiaLQ {
    position: absolute; 
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.VideoCallAppointment_book__25r-U {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 8px 50px 8px 50px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;

}

@media only screen and (max-width: 500px) {
    .VideoCallAppointment_body__3X4sl {
        padding: 10% 8% 10% 8%;
        height: auto;
    }

    .VideoCallAppointment_bluebox__1gdDt {
        height: 100%;
        padding: 20px 10px 20px 20px;
    }

    .VideoCallAppointment_outerDiv__1fsjF {
        flex-direction: column;
    }

    .VideoCallAppointment_leftDiv__3QHx5 {
        width: 100%;
    }

    .VideoCallAppointment_imgDiv__UiaLQ {
        display: none;
    }

    .VideoCallAppointment_rightDiv__25LUk {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .VideoCallAppointment_leftDivTitle__swGkU {
        font-size: 18px;
        line-height: 1.2;
    }

    .VideoCallAppointment_leftDivSub__1VF06 {
        font-size: 18px;
        line-height: 1.2;
    }

    .VideoCallAppointment_leftDivFreeConsultation__2GTbU {
        font-size: 8px;
    }

    .VideoCallAppointment_row__3xF8h {
        margin: 5px 0 5px 0 ;
    }

    .VideoCallAppointment_tag__1dQUV {
        font-size: 10px;
        width: 100px;
    }

    .VideoCallAppointment_inputText__3s1oq {
        font-size: 10px;
        width: 150px;
    }

    .VideoCallAppointment_inputSelect__2qKGv {
        font-size: 10px;
        width: 170px;
    }

    .VideoCallAppointment_timeslot__dEbD9 {
        font-size: 10px;
        padding: 10px;
        margin: 0 2.5px 0 2.5px;
    }

    .VideoCallAppointment_timeslotActive__2z2SY {
        font-size: 10px;
        padding: 10px;
        margin: 0 2.5px 0 2.5px;
    }

    .VideoCallAppointment_book__25r-U {
        font-size: 14px;
        padding: 4px 30px 4px 30px;
    }
}
.ScheduleCall_container__3ZD4e {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.ScheduleCall_body__1iRLH {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 10% 5% 10% ;
    height: 65vh;
}

.ScheduleCall_bluebox__3uxB0 {
    height: 120%;
    width: 100%;
    background: #F3F5FF;
    border-radius: 18px;
    padding: 20px 50px 20px 50px;
    position: relative;
}

.ScheduleCall_crossDiv__2-pXs {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
}

.ScheduleCall_outerDiv__xJjCZ {
    display: flex;
    height: 100%;
}

.ScheduleCall_leftDiv__2LMde {
    width: 35%;
}

.ScheduleCall_leftText__1fucN {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ScheduleCall_leftDivTitle__2e8FK {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.65px; 
    color: #000000; 
}

.ScheduleCall_leftDivSub__2ufJi {
    margin-top: 15px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 15px;
    color: #000000; 
}

.ScheduleCall_leftDivFreeConsultation__34TI9 {
    margin: 10px 0 0 0;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px; 
    color: #5D78DE;
    text-align: left;
}

.ScheduleCall_rightDiv__3Dc4L {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.ScheduleCall_row__2Txqs {
    display: flex;
    margin: 8px 0 8px 0;
}

.ScheduleCall_rowBook__3_2cS {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 8px 0 8px 0;
}

.ScheduleCall_bookText__2ADVL {
    margin-right: 20px;
    text-align: right;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #0A0A0A;
}

.ScheduleCall_price__36eGg {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 4px;
    color: #2EAFA3;
}

.ScheduleCall_tag__22m3l {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
    width: 150px;
}

.ScheduleCall_timeslotsDiv__pu0VK {
    display: flex;
}

.ScheduleCall_bookingForDiv__2GbtP {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.0973285);
    border-radius: 8px;
    padding: 5px;
}

.ScheduleCall_bookingForActive__3dnJQ {
    background: #F3F5FF;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
}

.ScheduleCall_bookingFor__2X-nA {
    padding: 10px 20px 10px 20px;
}

.ScheduleCall_timeslotActive__3gSza {
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.0973285);
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    cursor: pointer;
}

.ScheduleCall_timeslot__2N-ol {
    text-align: left;
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.ScheduleCall_inputText__36xQc {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 200px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.ScheduleCall_inputTextDetails__3H5e- {
    background-color: transparent;
    border: 1px solid #4663D0;
    border-radius: 8px;
    outline: none;
    padding: 8px;
    width: 145px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.ScheduleCall_inputTextEmail__3-k_G {
    background-color: transparent;
    border: 1px solid #4663D0;
    border-radius: 8px;
    outline: none;
    padding: 8px;
    width: 320px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.ScheduleCall_inputSelect__3wU4p {
    background: #5D78DE;
    box-shadow: 0px 2px 21px rgba(93, 120, 222, 0.336375);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 220px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}


.ScheduleCall_option__2OMVb {
    color: #ffffff;
}


.ScheduleCall_videocall__3lx1M {
    position: relative;
    width: 65%;
}

.ScheduleCall_imgDiv__2mauG {
    position: absolute; 
    bottom: -15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.ScheduleCall_book__YmHqr {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 8px 50px 8px 50px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;

}

@media only screen and (max-width: 500px) {
    .ScheduleCall_body__1iRLH {
        padding: 10% 8% 10% 8%;
        height: auto;
    }

    .ScheduleCall_bluebox__3uxB0 {
        height: 100%;
        padding: 20px 10px 20px 20px;
    }

    .ScheduleCall_outerDiv__xJjCZ {
        flex-direction: column;
    }

    .ScheduleCall_leftDiv__2LMde {
        width: 100%;
    }

    .ScheduleCall_imgDiv__2mauG {
        display: none;
    }

    .ScheduleCall_rightDiv__3Dc4L {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .ScheduleCall_leftDivTitle__2e8FK {
        font-size: 18px;
        line-height: 1.2;
    }

    .ScheduleCall_leftDivSub__2ufJi {
        font-size: 18px;
        line-height: 1.2;
    }

    .ScheduleCall_leftDivFreeConsultation__34TI9 {
        font-size: 8px;
    }

    .ScheduleCall_row__2Txqs {
        margin: 5px 0 5px 0 ;
    }

    .ScheduleCall_tag__22m3l {
        font-size: 10px;
        width: 100px;
    }

    .ScheduleCall_inputText__36xQc {
        font-size: 10px;
        width: 150px;
    }

    .ScheduleCall_inputTextDetails__3H5e- {
        font-size: 10px;
        width:60px;
        margin-left: 5px;
    }

    .ScheduleCall_inputTextEmail__3-k_G {
        font-size: 10px;
        width: 150px;
        margin-left: 5px;
    }

    .ScheduleCall_inputSelect__3wU4p {
        font-size: 10px;
        width: 170px;
    }

    .ScheduleCall_timeslot__2N-ol {
        font-size: 10px;
        padding: 5px;
        margin: 0 2.5px 0 2.5px;
    }

    .ScheduleCall_timeslotActive__3gSza {
        font-size: 10px;
        padding: 5px;
        margin: 0 2.5px 0 2.5px;
    }

    .ScheduleCall_book__YmHqr {
        font-size: 14px;
        padding: 4px 30px 4px 30px;
    }

    .ScheduleCall_bookingForDiv__2GbtP {
        margin-left: 5px;
    }

    .ScheduleCall_bookingFor__2X-nA {
        font-size: 12px;
        padding: 5px 10px 5px 10px;
    }

    .ScheduleCall_bookingForActive__3dnJQ {
        font-size: 12px;
        padding: 5px 10px 5px 10px;
    }

    .ScheduleCall_bookText__2ADVL {
        font-size: 12px;
    }

    .ScheduleCall_price__36eGg {
        font-size: 18px;
    }
}

.SubscribePlan_container__2jg0N {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.SubscribePlan_body__2ukDX {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 10% 5% 10% ;
    height: 65vh;
}

.SubscribePlan_bluebox__2hiNE {
    height: 120%;
    width: 100%;
    background: #F3F5FF;
    border-radius: 18px;
    padding: 20px 50px 20px 50px;
    position: relative;
}

.SubscribePlan_crossDiv__1hQ6S {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
}

.SubscribePlan_outerDiv__dd2tf {
    display: flex;
    height: 100%;
}

.SubscribePlan_leftDiv__2h6lz {
    width: 35%;
}

.SubscribePlan_leftText__2-dd5 {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.SubscribePlan_leftDivTitle__xd5xf {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.65px; 
    color: #000000; 
}

.SubscribePlan_leftDivSub__3ghGu {
    margin-top: 15px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 15px;
    color: #000000; 
}

.SubscribePlan_leftDivFreeConsultation__33Y0G {
    margin: 10px 0 0 0;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px; 
    color: #5D78DE;
    text-align: left;
}

.SubscribePlan_rightDiv__3E33C {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.SubscribePlan_row__-u1pP {
    display: flex;
    margin: 8px 0 8px 0;
}

.SubscribePlan_rowBook__xg6Lw {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 8px 0 8px 0;
}

.SubscribePlan_bookText__2W3K4 {
    margin-right: 20px;
    text-align: right;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #0A0A0A;
}

.SubscribePlan_price__1-eZR {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 4px;
    color: #2EAFA3;
}

.SubscribePlan_tag__ChWxS {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
    width: 150px;
}

.SubscribePlan_timeslotsDiv__2pCZb {
    display: flex;
}

.SubscribePlan_bookingForDiv__2ue18 {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.0973285);
    border-radius: 8px;
    padding: 5px;
}

.SubscribePlan_bookingForActive__oGwE6 {
    background: #F3F5FF;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
}

.SubscribePlan_bookingFor__TlHrW {
    padding: 10px 20px 10px 20px;
}

.SubscribePlan_timeslotActive__2Fjyq {
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.0973285);
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    cursor: pointer;
}

.SubscribePlan_timeslot__2TVcO {
    text-align: left;
    border-radius: 10px;
    margin: 0 5px 0 5px;
    padding: 10px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.SubscribePlan_inputText__Q2BBT {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 200px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.SubscribePlan_inputTextDetails__1D7mC {
    background-color: transparent;
    border: 1px solid #4663D0;
    border-radius: 8px;
    outline: none;
    padding: 8px;
    width: 145px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.SubscribePlan_inputTextEmail__Dk0lG {
    background-color: transparent;
    border: 1px solid #4663D0;
    border-radius: 8px;
    outline: none;
    padding: 8px;
    width: 320px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.SubscribePlan_inputSelect__1_fy1 {
    background: #5D78DE;
    box-shadow: 0px 2px 21px rgba(93, 120, 222, 0.336375);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 8px;
    width: 220px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}


.SubscribePlan_option__Pndgu {
    color: #ffffff;
}


.SubscribePlan_videocall__yn0Ke {
    position: relative;
    width: 65%;
}

.SubscribePlan_imgDiv__1cljv {
    position: absolute; 
    bottom: -15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.SubscribePlan_book__1BUDk {
    background: #2EAFA3;
    box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
    border-radius: 10px;
    padding: 8px 50px 8px 50px;
    cursor: pointer;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;

}

@media only screen and (max-width: 500px) {
    .SubscribePlan_body__2ukDX {
        padding: 10% 8% 10% 8%;
        height: auto;
    }

    .SubscribePlan_leftText__2-dd5 {
        margin-top: 70px;
    }

    .SubscribePlan_bluebox__2hiNE {
        height: 100%;
        padding: 20px 10px 20px 20px;
    }

    .SubscribePlan_outerDiv__dd2tf {
        flex-direction: column;
    }

    .SubscribePlan_leftDiv__2h6lz {
        width: 100%;
    }

    .SubscribePlan_imgDiv__1cljv {
        display: none;
    }

    .SubscribePlan_rightDiv__3E33C {
        width: 100%;
        margin: 20px 0 0 0;
    }

    .SubscribePlan_leftDivTitle__xd5xf {
        font-size: 18px;
        line-height: 1.2;
    }

    .SubscribePlan_leftDivSub__3ghGu {
        font-size: 18px;
        line-height: 1.2;
    }

    .SubscribePlan_leftDivFreeConsultation__33Y0G {
        font-size: 8px;
    }

    .SubscribePlan_row__-u1pP {
        margin: 5px 0 5px 0 ;
    }

    .SubscribePlan_tag__ChWxS {
        font-size: 10px;
        width: 100px;
    }

    .SubscribePlan_inputText__Q2BBT {
        font-size: 10px;
        width: 150px;
    }

    .SubscribePlan_inputTextDetails__1D7mC {
        font-size: 10px;
        width:60px;
        margin-left: 5px;
    }

    .SubscribePlan_inputTextEmail__Dk0lG {
        font-size: 10px;
        width: 150px;
        margin-left: 5px;
    }

    .SubscribePlan_inputSelect__1_fy1 {
        font-size: 10px;
        width: 170px;
    }

    .SubscribePlan_timeslot__2TVcO {
        font-size: 10px;
        padding: 5px;
        margin: 0 2.5px 0 2.5px;
    }

    .SubscribePlan_timeslotActive__2Fjyq {
        font-size: 10px;
        padding: 5px;
        margin: 0 2.5px 0 2.5px;
    }

    .SubscribePlan_book__1BUDk {
        font-size: 14px;
        padding: 4px 30px 4px 30px;
    }

    .SubscribePlan_bookingForDiv__2ue18 {
        margin-left: 5px;
    }

    .SubscribePlan_bookingFor__TlHrW {
        font-size: 12px;
        padding: 5px 10px 5px 10px;
    }

    .SubscribePlan_bookingForActive__oGwE6 {
        font-size: 12px;
        padding: 5px 10px 5px 10px;
    }

    .SubscribePlan_bookText__2W3K4 {
        font-size: 12px;
    }

    .SubscribePlan_price__1-eZR {
        font-size: 18px;
    }
}

.Header_headerContainer__1YLYN {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 15px 50px 15px 50px;
}

.Header_menuContainer__1bx8c {
  display: flex;
  flex-direction: row;
}

.Header_menuItem__39v8B {
  margin: 10px 0px 10px 0px;
  padding: 5px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.Header_logout__1UGPC {
  margin: 0 0 0 20px;
  position: absolute;
}

.Header_profile__1y4dl {
  margin: 0 5px 0 -20px;
  position: absolute;
  cursor: pointer;
}

.Header_signin__3umE2 {
  width: 80px;
  margin: 10px 0px 10px 0px;
  padding: 5px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.Header_pharmacyMenuItem__1_mOK {
  margin: 10px 5px 10px 5px;
  padding: 5px 20px 5px 20px;
  background-color: #2eafa3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.53);
  text-decoration: none;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.Header_cartIconItem__1Kyes {
  margin: 10px 10px 10px -10px;
  padding: 5px 15px 5px 25px;
  background-color: #e0f3f1;
  border-radius: 10px;
  z-index: -1;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.53);
  position: relative;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #24b2a5;
  cursor: pointer;
}

.Header_cartCount__3rjny {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f75906;
  color: white;
  font-size: 10px;
  box-shadow: 4px 4px 15px rgba(255, 0, 0, 0.25);
}

.Header_cover__3T11U {
  padding: 5px 15px 5px 25px;
  position: relative;
  left: 20px;
  margin: 0 -25px 0 -25px;
  cursor: pointer;
}

.Header_covidCareDiv__3eMMe {
  display: flex;
}

.Header_comingSoon__3Ww1j {
  position: absolute;
  right: 200px;
  top: 65px;

  font-family: "Poppins";
  font-size: 11px;
  font-weight: 500;
  color: #fa6400;
}

.Header_covidCareMenuItem__XUpo0 {
  margin: 10px 10px 10px 10px;
  padding: 5px 20px 5px 20px;
  background-color: rgba(46, 175, 163, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.Header_alarm__iCW8Q {
  color: #24b2a5;
  margin-left: -25px;
}

.Header_logo__3_SJs {
  height: 30px;
  width: 120px;
}

.Header_brandBgNav__1tfP4 {
  position: absolute;
  top: 0;
  left: 15px;
  z-index: -1;
}

.Header_loginModalBg__3IwHb {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.54);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Header_loginModal__w5Aa6 {
  padding: 50px 100px 50px 100px;
  background-color: #fff;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Header_loginLogo__dVZYl {
  width: 75px;
  margin: 0 0 10px 0;
}

.Header_modalTitle__1wbz9 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 25px;
  margin: 0 0 20px 0;
  text-align: center;
  width: 70%;
}

.Header_phoneNumberDiv__4wlFc {
  background: #e0f3f1;
  border-radius: 10px;
  width: 350px;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}

.Header_phoneNum__3bRok {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2eafa3;
  opacity: 0.6;
  border: none;
  outline: none;
  background-color: transparent;
}

.Header_sendOTP__2nLkE {
  padding: 5px 10px 5px 10px;
  background: #fefeff;
  /* Green shadow */
  box-shadow: 0px 6px 29px rgba(46, 175, 163, 0.320258);
  border-radius: 5px;
  cursor: pointer;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  color: #2eafa3;
}

.Header_or__2umh7 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;

  margin: 0 0 20px 0;
}

.Header_socialLogo__3YfX8 {
  width: 10%;
}

.Header_signupText__3UIP_ {
  width: 90%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Header_socialButtonE__3nLZo {
  width: 350px;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(120.24deg, #e3e3ff 10.68%, #afa4c6 100%);
  margin: 10px 0 10px 0;
  cursor: pointer;
}

.Header_socialButtonG__lY3xU {
  width: 350px;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(180deg, #ffd5d3 0%, #e7b7b5 100%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0;
  cursor: pointer;
}

.Header_socialButtonF__3ojuJ {
  width: 350px;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(152.43deg, #6ca0da 24.65%, #5874de 98.71%);
  border: 1px solid #6c9fda;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 10px 0;
  cursor: pointer;
}

.Header_ltablet__ZPWqJ {
  position: absolute;
  top: 100px;
  left: 32%;
}

.Header_ltabletO__TMen6 {
  position: absolute;
  top: 20%;
  left: 32%;
}

.Header_rtablet__1Xx8H {
  position: absolute;
  top: 60%;
  right: 28%;
}

.Header_rtabletO__32j5Z {
  position: absolute;
  top: 60%;
  right: 26%;
}

.Header_phoneNumberDivOTP__3LDI0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 40px 0;
}

.Header_phoneNumOTP__1n1E- {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  color: #4663d0;
}

.Header_editnum__39m7q {
  margin: 0 0 0 10px;
  cursor: pointer;
}

.Header_otpDiv__2Hyhy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header_otpsubmit__Rc_5S {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 20px;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  cursor: pointer;
  background: linear-gradient(152.43deg, #6ca0da 24.65%, #5874de 98.71%);
}

.Header_resendOTPDiv__1Ru2K {
  margin: 15px 0 0 0;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;

  cursor: pointer;

  color: #4663d0;
}

.Header_ourStory__3jnjy {
  text-decoration: none;
  color: black;
}
.Header_brand__x6YrE {
  cursor: pointer;
}
.Header_cartContainer__3JD8B {
  margin: 10px 5px 10px 5px;
  padding: 5px 20px 5px 20px;

  background-color: #2eafa3;
  border-radius: 10px;

  box-shadow: 0 6px 29px 0 rgba(46, 175, 163, 0.53);
  text-decoration: none;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  position: relative;
}
.Header_marginRight__32aJQ {
  margin-right: 10px;
}
@media only screen and (max-width: 500px) {
  .Header_menuContainer__1bx8c {
    /* display: none; */
    justify-content: flex-end;
    /* margin-right: -45px; */
  }

  .Header_headerContainer__1YLYN {
    /* border: 2px solid green; */
  }

  .Header_menuItem__39v8B,
  .Header_pharmacyMenuItem__1_mOK,
  .Header_covidCareDiv__3eMMe {
    display: none;
  }

  .Header_logout__1UGPC {
    margin: 0 0 0 10px;
  }

  .Header_cartIconItem__1Kyes {
    margin-right: -5px;
    padding: 5px 15px 5px 15px;
  }

  .Header_logo__3_SJs {
    height: 30px;
    width: 100px;
  }

  .Header_cover__3T11U {
  }

  .Header_signin__3umE2 {
    justify-content: flex-end;
    padding: 5px 5px 5px 0px;
  }

  .Header_ltablet__ZPWqJ {
  }

  .Header_ltablet__ZPWqJ {
    position: absolute;
    top: 100px;
    left: 0%;
  }

  .Header_rtablet__1Xx8H {
    position: absolute;
    top: 85%;
    right: 28%;
  }

  .Header_ltabletO__TMen6 {
    left: 10%;
  }

  .Header_rtabletO__32j5Z {
    top: 72%;
  }

  .Header_loginModal__w5Aa6 {
    padding: 50px 10px 50px 10px;
    margin: 0 20px 0 20px;
  }
}


@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Roboto");

.container {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2;
    color: #000;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 3% 10% 5% 10% ;
}

.back {
    display: none;
    position: absolute;
    top: 19.5%;
    left: 10%;
}

.backicon {
    width: 100%;
}

.innerBody {
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    background: #24B2A525;
    mix-blend-mode: normal;
    box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.136937);
    border-radius: 18px;
    padding: 40px 70px 20px 70px;
}

.lTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.78px;
    text-align: left;
    color: #000000;
}

.lSubtitle {
    margin: 10px 0 15px 0;
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.0833333px;
    text-align: left;
    color: #000000;
}

.whiteCard {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
}

.topSection {
    padding: 0 0 15px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.28);
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: left;
    letter-spacing: -0.116667px;
    color: #000000;
    
}

.rText {
    margin: 5px 0 5px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.116667px;
    color: #000000;
    text-align: right;
    
}

.lTextG {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: left;
    letter-spacing: -0.116667px;
    color: #2EAFA3;
    
}

.rTextG {
    margin: 5px 0 5px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    letter-spacing: -0.116667px;
    color: #2EAFA3;
    text-align: right;
    
}

.bottom {
    margin: 10px 0 0 0;
}

.inclusive {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    font-size: 10px;
    line-height: 32px;
    text-align: left;
    color: #7A7A7A;
    margin: -15px 0 10px 0;
}

.brownBox {
    background: #FFECEB;
    border-radius: 18px;
    padding: 25px 25px 25px 25px;
    margin: 15px 0 0 0;
}

.bottomText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 32px;
    color: #4663D0;
    
}

.lBottomTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
}

.lBottomSubtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #4663D0;
}

.lBottomPhone {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.107026);
    border-radius: 25.5px;
    padding: 10px 20px 10px 20px;
    margin: 10px 0 0 0;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;

    color: #000000;

}

.phoneDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.right {
    padding: 30px 80px 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.backButton {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    text-decoration: none;
    background: linear-gradient(152.43deg, #6CA0DA 24.65%, #5874DE 98.71%);
    box-shadow: 0px 12px 42px rgba(0, 0, 0, 0.0917013);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    margin: 30px 0 0 0;
    cursor: pointer;
}


@media only screen and (max-width: 500px) {
    .body {
      padding: 10% 8% 10% 8%;
    }

    .innerBody {
        margin: 20px 0 0 0;
        padding: 40px 20px 20px 20px;
        flex-direction: column;
        width: 90%;
    }

    .back {
        display: none;
    }

    .lTitle {
        font-size: 22px;
        line-height: 1.2;
    }

    .lSubtitle {
        font-size: 12px;
    }

    .right {
        padding: 30px 20px 30px 20px;
    }
}